import { TextField, Typography } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ProjectContext } from "../data/contexts/ProjectContext";
import BaseCard from "../utils/BaseCard";
import CenteredContent from "../utils/CenteredContent";
import ProjectViewer, { ProjectsParams } from "./Project";
import ProjectsSummary from "./components/ProjectsSummary";
import GdButton from "../utils/GdButton";
import { log, Lvl } from "../utils/log";
import { Project, ProjectStatus } from "../data/generated/graphql";
import GdAlert from "../utils/GdAlert";
import { dateConvertToDataDate } from "../data/dataConvertors";

import "./Projects.css";
import { UserContext } from "../data/contexts/UserContext";
import { AssistantContext } from "../data/contexts/AssistantContext";

const Projects: FC = () => {
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState<Project | null>(null);
  const [projectToDuplicate, setProjectToDuplicate] = useState<Project | null>(null);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [duplicateName, setDuplicateName] = useState("");
  const { projectId } = useParams() as ProjectsParams;
  const { currentProject, getProjectDetails, projects, getMyProjects, updateProject, duplicateProject } =
    useContext(ProjectContext);
  const { isAssistantActive, openGreeny, setOpenGreeny } = useContext(AssistantContext);
  const { userInfo } = useContext(UserContext);
  const { t } = useTranslation(["project", "global", "aiAssistant"]);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const openAssistant = (): void => setOpenGreeny(!openGreeny);

  const handleCreateProject = (): void => history.push("/projects/create");

  useEffect(() => {
    if (projectId) {
      setLoading(true);
      if (projectId && currentProject?.id !== projectId) {
        getProjectDetails(projectId)
          .then(() => setLoading(false))
          .catch((err) => {
            log("Error while getting project", Lvl.ERROR, err);
            setLoading(false);
          });
      } else setLoading(false);
    } else {
      setLoading(true);
      getMyProjects().then(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  if (loading) return <CenteredContent loading />;

  if (projectId) {
    if (currentProject && currentProject.id === projectId) return <ProjectViewer project={currentProject} />;
    return <CenteredContent errorText={t("errorWhileGetting")} />;
  }

  const orderByDateDesc = (p1: Project, p2: Project): number => {
    if (!p1.creation || !p2.creation || p1.creation < p2.creation) return 1;
    return -1;
  };
  const myProjects = projects.filter((p) => p.userId === userInfo?.id);
  const simulatedProjects = myProjects.filter((p) => p.status === ProjectStatus.Simulation).sort(orderByDateDesc);
  const ongoingProjects = myProjects
    .filter((p) => p.status === ProjectStatus.Launched || p.status === ProjectStatus.PreworkCompleted)
    .sort(orderByDateDesc);
  const finishedProjects = myProjects
    .filter((p) => p.status === ProjectStatus.Finished || p.status === ProjectStatus.PostworkCompleted)
    .sort(orderByDateDesc);
  const canceledProjects = myProjects.filter((p) => p.status === ProjectStatus.Canceled).sort(orderByDateDesc);
  const readOnlyProjects = projects.filter((p) => myProjects.findIndex((mp) => mp.id === p.id) === -1);

  const deleteProject = async (): Promise<void> => {
    setIsDeleting(true);
    const result = await updateProject({
      id: projectToDelete?.id || "",
      cancellation: {
        reason: "Supprimé depuis la liste des projets",
        details: "Simulation supprimée via le bouton de la liste",
        date: dateConvertToDataDate(new Date()),
      },
    });
    if (!result) {
      enqueueSnackbar(t("errorWhileUpdating"), { variant: "error" });
    } else {
      enqueueSnackbar(t("updateSuccess"), { variant: "success" });
    }
    setIsDeleting(false);
    setProjectToDelete(null);
  };
  const duplicateTheProject = async (): Promise<void> => {
    setIsDuplicating(true);
    const result = await duplicateProject(projectToDuplicate?.id as string, duplicateName);
    if (!result) {
      enqueueSnackbar(t("errorWhileUpdating"), { variant: "error" });
    } else {
      enqueueSnackbar(t("updateSuccess"), { variant: "success" });
    }
    setIsDuplicating(false);
    setProjectToDuplicate(null);
    setDuplicateName("");
  };
  const setDup = (project: Project): void => {
    setDuplicateName(project.name);
    setProjectToDuplicate(project);
  };
  const mySimulatedProjects =
    simulatedProjects.length > 0 ? (
      <BaseCard className="big-margin-bottom">
        <div className="projects-header">
          <Typography variant="h6">{t("mySimulatedProjects")}</Typography>
          <GdButton label={t("createProject")} onClick={handleCreateProject} />
        </div>
        <ProjectsSummary
          projects={simulatedProjects}
          showCreation
          deleteProject={setProjectToDelete}
          duplicateProject={setDup}
        />
        <GdAlert
          body={t("confirmDeleteProject")}
          title={t("global:warning")}
          open={Boolean(projectToDelete)}
          onClose={() => setProjectToDelete(null)}
          okButtonClick={deleteProject}
          okButtonLoading={isDeleting}
        />
        <GdAlert
          body={
            <>
              <Typography>{t("projectDuplicateNameMandatory")}</Typography>
              <TextField
                label={t("projectDuplicateName")}
                value={duplicateName}
                onChange={(e) => setDuplicateName(e.target.value)}
                style={{ marginTop: 16 }}
                fullWidth
              />
            </>
          }
          title={t("duplicateProject")}
          open={Boolean(projectToDuplicate)}
          onClose={() => setProjectToDuplicate(null)}
          okButtonClick={duplicateTheProject}
          okButtonLoading={isDuplicating}
          okButtonDisabled={duplicateName.length === 0 || duplicateName === projectToDuplicate?.name}
        />
      </BaseCard>
    ) : undefined;
  const myOngoingProjects =
    ongoingProjects.length > 0 ? (
      <BaseCard className="big-margin-bottom">
        <div className="projects-header">
          <Typography variant="h6">{t("myOngoingProjects")}</Typography>
        </div>
        <ProjectsSummary projects={ongoingProjects} showSignaturesStatus duplicateProject={setDup} />
        <GdAlert
          body={
            <>
              <Typography>{t("projectDuplicateNameMandatory")}</Typography>
              <TextField
                label={t("projectDuplicateName")}
                value={duplicateName}
                onChange={(e) => setDuplicateName(e.target.value)}
                style={{ marginTop: 16 }}
                fullWidth
              />
            </>
          }
          title={t("duplicateProject")}
          open={Boolean(projectToDuplicate)}
          onClose={() => setProjectToDuplicate(null)}
          okButtonClick={duplicateTheProject}
          okButtonLoading={isDuplicating}
          okButtonDisabled={duplicateName.length === 0 || duplicateName === projectToDuplicate?.name}
        />
      </BaseCard>
    ) : undefined;
  const myFinishedProjects =
    finishedProjects.length > 0 ? (
      <BaseCard className="big-margin-bottom">
        <div className="projects-header">
          <Typography variant="h6">{t("myFinishedProjects")}</Typography>
        </div>
        <ProjectsSummary projects={finishedProjects} showLatestUpdate showSignaturesStatus duplicateProject={setDup} />
        <GdAlert
          body={
            <>
              <Typography>{t("projectDuplicateNameMandatory")}</Typography>
              <TextField
                label={t("projectDuplicateName")}
                value={duplicateName}
                onChange={(e) => setDuplicateName(e.target.value)}
                style={{ marginTop: 16 }}
                fullWidth
              />
            </>
          }
          title={t("duplicateProject")}
          open={Boolean(projectToDuplicate)}
          onClose={() => setProjectToDuplicate(null)}
          okButtonClick={duplicateTheProject}
          okButtonLoading={isDuplicating}
          okButtonDisabled={duplicateName.length === 0 || duplicateName === projectToDuplicate?.name}
        />
      </BaseCard>
    ) : undefined;
  const myReadOnlyProjects =
    readOnlyProjects.length > 0 ? (
      <BaseCard>
        <div className="projects-header">
          <Typography variant="h6">{t("myReadOnlyProjects")}</Typography>
        </div>
        <ProjectsSummary projects={readOnlyProjects} showSignaturesStatus readOnly />
      </BaseCard>
    ) : undefined;
  const myCanceledProjects =
    canceledProjects.length > 0 ? (
      <BaseCard>
        <div className="projects-header">
          <Typography variant="h6">{t("myCanceledProjects")}</Typography>
        </div>
        <ProjectsSummary projects={canceledProjects} showSignaturesStatus duplicateProject={setDup} />
        <GdAlert
          body={
            <>
              <Typography>{t("projectDuplicateNameMandatory")}</Typography>
              <TextField
                label={t("projectDuplicateName")}
                value={duplicateName}
                onChange={(e) => setDuplicateName(e.target.value)}
                style={{ marginTop: 16 }}
                fullWidth
              />
            </>
          }
          title={t("duplicateProject")}
          open={Boolean(projectToDuplicate)}
          onClose={() => setProjectToDuplicate(null)}
          okButtonClick={duplicateTheProject}
          okButtonLoading={isDuplicating}
          okButtonDisabled={duplicateName.length === 0 || duplicateName === projectToDuplicate?.name}
        />
      </BaseCard>
    ) : undefined;
  return projects.length > 0 ? (
    <div>
      {mySimulatedProjects}
      {simulatedProjects.length === 0 ? (
        <GdButton
          label={t("createProject")}
          onClick={handleCreateProject}
          className="big-margin-bottom"
          width="large"
        />
      ) : undefined}
      {myOngoingProjects}
      {myFinishedProjects}
      {!isAssistantActive ? (
        <GdButton label={t("aiAssistant:subscription.callToAction")} onClick={openAssistant} fullWidth />
      ) : undefined}
      {myReadOnlyProjects}
      {myCanceledProjects}
    </div>
  ) : (
    <CenteredContent>
      <div className="empty">
        <Typography variant="h6" className="big-margin-bottom">
          {t("noProjectYet")}
        </Typography>
        <GdButton label={t("createProject")} onClick={handleCreateProject} width="large" />
      </div>
    </CenteredContent>
  );
};

export default Projects;
