import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";
import { SignerInput } from "../../../data/generated/graphql";
import CompanyTypeList from "../../../data/lists/CompanyTypeList";
import RCSList from "../../../data/lists/RCSList";
import GdButton from "../../../utils/GdButton";
import { TextMaskNumber, TextMaskZipCode } from "../../../utils/TextMasks";

interface CompanyStepProps {
  signer: SignerInput;
  setSigner: (signer: SignerInput) => void;
  setStep: (step: number) => void;
}

const CompanyStep: FC<CompanyStepProps> = ({ signer, setSigner, setStep }) => {
  const { t } = useTranslation(["vehicles", "project", "user"]);

  const isDisbaled =
    !signer.company.rcs ||
    signer.company.rcs?.length === 0 ||
    !signer.company.name ||
    signer.company.name.length === 0 ||
    !signer.company.companyType ||
    signer.company.companyType.length === 0 ||
    !signer.company.shareCapital ||
    signer.company.shareCapital?.length === 0 ||
    !signer.company.rcs ||
    signer.company.rcs.length === 0 ||
    !signer.company.address ||
    signer.company.address.length === 0 ||
    !signer.company.zipCode ||
    signer.company.zipCode.length === 0 ||
    !signer.company.city ||
    signer.company.city.length === 0;

  const changeCompanyField = (field: string) => (e: ChangeEvent<HTMLInputElement>) =>
    setSigner({ ...signer, company: { ...signer.company, [field]: e.target.value } });
  const changeCompanyFieldFromSelect = (field: string) => (e: SelectChangeEvent<string | null>) =>
    setSigner({ ...signer, company: { ...signer.company, [field]: e.target.value } });

  return (
    <>
      <Stack direction="row" gap="16px" width="700px">
        <TextField
          fullWidth
          label={t("project:company.name")}
          variant="outlined"
          value={signer.company.name}
          onChange={changeCompanyField("name")}
          autoComplete="off"
          InputLabelProps={{ shrink: Boolean(signer.company.name) }}
        />
        <FormControl className="not-only-textfield" fullWidth>
          <InputLabel id="company-type-label">{t("project:company.companyType")}</InputLabel>
          <Select
            labelId="company-type-label"
            label={t("project:company.companyType")}
            variant="outlined"
            value={signer.company.companyType}
            onChange={changeCompanyFieldFromSelect("companyType")}>
            <MenuItem value={undefined}>
              <em>{t("user:civ.select")}</em>
            </MenuItem>
            {CompanyTypeList.map((ct) => (
              <MenuItem value={ct.key} key={ct.key}>
                {ct.key === ct.name ? ct.key : `${ct.name} (${ct.key})`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label={t("project:company.shareCapital")}
          variant="outlined"
          value={signer.company.shareCapital}
          onChange={changeCompanyField("shareCapital")}
          className="not-only-textfield"
          InputProps={{
            inputComponent: TextMaskNumber as any,
          }}
          autoComplete="off"
        />
      </Stack>
      <Stack direction="row" gap="16px" width="700px">
        <FormControl className="not-only-textfield" fullWidth>
          <InputLabel id="company-rcs-label">{t("project:company.rcs")}</InputLabel>
          <Select
            labelId="company-rcs-label"
            label={t("project:company.rcs")}
            variant="outlined"
            value={signer.company.rcs}
            onChange={changeCompanyFieldFromSelect("rcs")}>
            <MenuItem value={undefined}>
              <em>{t("user:civ.select")}</em>
            </MenuItem>
            {RCSList.map((r) => (
              <MenuItem value={r} key={r}>
                {r}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Stack direction="row" gap="16px" width="700px">
        <TextField
          fullWidth
          style={{ width: "400px" }}
          label={t("project:company.address")}
          variant="outlined"
          value={signer.company.address}
          onChange={changeCompanyField("address")}
          autoComplete="off"
          InputLabelProps={{ shrink: Boolean(signer.company.address) }}
        />
        <TextField
          fullWidth
          style={{ width: "100px" }}
          label={t("project:company.zipCode")}
          variant="outlined"
          value={signer.company.zipCode}
          onChange={changeCompanyField("zipCode")}
          InputProps={{
            inputComponent: TextMaskZipCode as any,
          }}
          className="not-only-textfield"
          autoComplete="off"
          InputLabelProps={{ shrink: Boolean(signer.company.zipCode) }}
        />
        <TextField
          style={{ width: "200px" }}
          label={t("project:company.city")}
          variant="outlined"
          value={signer.company.city}
          onChange={changeCompanyField("city")}
          className="not-only-textfield"
          autoComplete="off"
          InputLabelProps={{ shrink: Boolean(signer.company.city) }}
        />
      </Stack>
      <Stack direction="row" gap="16px" justifyContent="center" marginTop="16px">
        <GdButton label={t("previous")} color="inherit" onClick={() => setStep(1)} />
        <GdButton label={t("next")} onClick={() => setStep(3)} disabled={isDisbaled} />
      </Stack>
    </>
  );
};

export default CompanyStep;
