import { createContext, FC, PropsWithChildren, useState } from "react";
import { Form, useFormGetAllForAllSectorsQuery, useFormGetByIdQuery } from "../generated/graphql";

export interface FormContextProps {
  formLoading: boolean;
  forms: Form[];
  getAllForms: () => Promise<void>;
  getFormById: (id: string) => Promise<Form>;
}

const initialContext: FormContextProps = {
  formLoading: false,
  forms: [],
  getAllForms: () => Promise.resolve(),
  getFormById: () => Promise.resolve({ id: "none" }),
};

export const FormContext = createContext<FormContextProps>(initialContext);

export const FormProvider: FC<PropsWithChildren> = ({ children }) => {
  const [formLoading, setFormLoading] = useState(false);
  const [forms, setForms] = useState<Form[]>([]);
  const { refetch: fetchAll } = useFormGetAllForAllSectorsQuery({ skip: true });
  const { refetch: fetchById } = useFormGetByIdQuery({ skip: true });

  const tryGetForm = (id: string): Form | null => {
    const form = forms.find((f) => f.id === id);
    return form && form.fields && form.fields.length > 0 ? form : null;
  };

  const getAllForms = async (): Promise<void> => {
    setFormLoading(true);
    const result = await fetchAll();
    setForms(result.data.formGetAll || []);
    setFormLoading(false);
  };

  const getFormById = async (id: string): Promise<Form> => {
    const localForm = tryGetForm(id);
    if (localForm) return Promise.resolve(localForm);
    setFormLoading(true);
    const result = await fetchById({ id });
    if (!result.data.formGetById) throw new Error("Form not found");
    setForms(forms.concat(result.data.formGetById));
    setFormLoading(false);
    return result.data.formGetById;
  };

  return (
    <FormContext.Provider
      value={{
        formLoading,
        forms,
        getAllForms,
        getFormById,
      }}>
      {children}
    </FormContext.Provider>
  );
};
