/* eslint-disable react/require-default-props */
import { Card, Stack, Typography } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ProjectsParams } from "./Project";
import { SubProjectContext } from "../data/contexts/SubProjectContext";
import CenteredContent from "../utils/CenteredContent";
import GdButton from "../utils/GdButton";
import FleetProject from "./fleetComponents/FleetProject";

interface FleetProjectsProps {
  enroll?: boolean;
  enrollAsFleetManager?: boolean;
}

const FleetProjects: FC<FleetProjectsProps> = ({ enroll = false, enrollAsFleetManager = false }) => {
  const [loading, setLoading] = useState(false);
  const [enrolling, setEnrolling] = useState(false);
  const { enrollToSubProject, fleetSubProjects, getMyFleetSubProjects, enrollAsFleetManagerToVehicleList } =
    useContext(SubProjectContext);
  const { projectId, vehicleListId } = useParams() as ProjectsParams;
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation(["vehicles", "global"]);
  if (enroll && projectId && fleetSubProjects.findIndex((fsp) => fsp.projectId === projectId) !== -1)
    history.push(`/fleet/${projectId}`);
  else if (
    enrollAsFleetManager &&
    vehicleListId &&
    fleetSubProjects.findIndex((fsp) => fsp.vehicleLists?.findIndex((vl) => vl.id === vehicleListId) !== -1) !== -1
  )
    history.push(
      `/fleet/${
        fleetSubProjects.find((fsp) => fsp.vehicleLists?.findIndex((vl) => vl.id === vehicleListId) !== -1)?.projectId
      }`,
    );
  else if ((pathname === "/fleet" || pathname === "/fleet/") && fleetSubProjects.length === 1)
    history.push(`/fleet/${fleetSubProjects[0].projectId}`);
  else if (fleetSubProjects.length > 0 && !enrollAsFleetManager && projectId !== fleetSubProjects[0].projectId)
    history.replace(`/fleet/${fleetSubProjects[0].projectId}`);
  useEffect(() => {
    (async () => {
      if (!enroll && !enrollAsFleetManager) {
        setLoading(true);
        await getMyFleetSubProjects();
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);
  const enrollToProject = async (): Promise<void> => {
    setEnrolling(true);
    if (enrollAsFleetManager) await enrollAsFleetManagerToVehicleList(vehicleListId || "");
    else await enrollToSubProject(projectId || "");
    setEnrolling(false);
  };

  return (enroll && (projectId?.length || 0) > 0) || (enrollAsFleetManager && (vehicleListId?.length || 0) > 0) ? (
    <div className="project-name-root">
      <Card sx={{ padding: "32px" }}>
        <Stack direction="column" justifyContent="center" alignItems="center" gap="32px">
          <Typography variant="h6">
            {t(enrollAsFleetManager ? "enrollToProjectAsFleetManager" : "enrollToProject")}
          </Typography>
          {enrolling ? (
            <CenteredContent loading />
          ) : (
            <Stack direction="row" justifyContent="center" alignItems="center" gap="32px">
              <GdButton label={t("global:yesOrNo.no")} onClick={() => history.push("/")} color="inherit" />
              <GdButton label={t("global:yesOrNo.yes")} onClick={enrollToProject} />
            </Stack>
          )}
        </Stack>
      </Card>
    </div>
  ) : loading ? (
    <CenteredContent loading />
  ) : (
    <FleetProject
      projectId={
        projectId ||
        fleetSubProjects.find((fsp) => fsp.vehicleLists?.findIndex((vl) => vl.id === vehicleListId) !== -1)
          ?.projectId ||
        ""
      }
    />
  );
};

export default FleetProjects;
