import { styled } from "@mui/material";
import { FC, useContext, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import Dashboard from "../common/Dashboard";
import Forms from "../common/Forms";
import News from "../common/News";
import Profile from "../profile/Profile";
import CreateProject from "../project/CreateProject";
import Projects from "../project/Projects";
import Signature from "../public/Signature";
import SigningFinished from "../public/SigningFinished";
import AppBar from "./AppBar";
import AppDrawer, { drawerWidth } from "./AppDrawer";
import UserMenu from "./UserMenu";

import Actions from "../common/Actions";
import AiHistory from "../common/AiHistory";
import Articles from "../common/Articles";
import { AssistantContext } from "../data/contexts/AssistantContext";
import { ArticleType } from "../data/generated/graphql";
import AiAssistant from "../project/components/AiAssistant";
import "./Navigation.css";
import AssistantSubscriptionModal from "../common/AssistantSubcriptionModal";
import FleetProjects from "../project/FleetProjects";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(({ theme, about }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `56px`,
  ...(about === "opened" && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${drawerWidth}px`,
  }),
}));

interface NavigationProps {
  signOut: () => void;
}

const Navigation: FC<NavigationProps> = ({ signOut }) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { openGreeny, openGreenySubscription, setOpenGreenySubscription } = useContext(AssistantContext);
  const { pathname } = useLocation();
  const history = useHistory();

  const closeMenu = (): void => {
    setAnchorEl(null);
  };
  const navIfRelevant = (path: string): void => {
    if (path !== pathname) {
      history.push(path);
    }
    setMenuOpen(false);
  };
  const closeDrawer = (): void => setMenuOpen(false);

  return (
    <Switch>
      <Route path="/yousign/:status">
        <SigningFinished />
      </Route>
      <Route path="/sign/:projectId/:type">
        <Signature />
      </Route>
      <Route path="/sign/:projectId">
        <Signature />
      </Route>
      <Route path="/">
        <div className="nav-root" style={{ background: 'url("/logo_bg.png") no-repeat 97% 97%' }}>
          <AppBar menuOpen={menuOpen} setMenuOpen={setMenuOpen} setAnchorEl={setAnchorEl} />
          <Main className={`page-root ${openGreeny ? "ai-assistant-open" : ""}`} about={menuOpen ? "opened" : "closed"}>
            <div className="panel">
              <Switch>
                <Route path="/actions">
                  <Actions />
                </Route>
                <Route path="/profile">
                  <Profile />
                </Route>
                <Route path="/news">
                  <News />
                </Route>
                <Route path="/forms">
                  <Forms />
                </Route>
                <Route path="/projects/create">
                  <CreateProject />
                </Route>
                <Route path="/projects/:projectId">
                  <Projects />
                </Route>
                <Route path="/projects">
                  <Projects />
                </Route>
                <Route path="/projects">
                  <Projects />
                </Route>
                <Route path="/faq">
                  <Articles type={ArticleType.Faq} />
                </Route>
                <Route path="/tips">
                  <Articles type={ArticleType.Tips} />
                </Route>
                <Route path="/assistantHistory">
                  <AiHistory />
                </Route>
                <Route path="/enroll/:projectId">
                  <FleetProjects enroll />
                </Route>
                <Route path="/enrollAsFleetManager/:vehicleListId">
                  <FleetProjects enrollAsFleetManager />
                </Route>
                <Route path="/fleet/:projectId">
                  <FleetProjects />
                </Route>
                <Route path="/fleet">
                  <FleetProjects />
                </Route>
                <Route path="/">
                  <Dashboard />
                </Route>
              </Switch>
            </div>
            <AiAssistant />
          </Main>
          <AssistantSubscriptionModal open={openGreenySubscription} onClose={() => setOpenGreenySubscription(false)} />
          <UserMenu anchorEl={anchorEl} close={closeMenu} nav={navIfRelevant} signOut={signOut} />
          <AppDrawer open={menuOpen} closeDrawer={closeDrawer} nav={navIfRelevant} />
        </div>
      </Route>
    </Switch>
  );
};

export default Navigation;
