/* eslint-disable jsx-a11y/no-static-element-interactions */
import { AccountCircle, Close, Replay, Send } from "@mui/icons-material";
import { Button, Card, CircularProgress, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Theme from "../../Theme";
import { AssistantContext } from "../../data/contexts/AssistantContext";
import GdButton from "../../utils/GdButton";
import { Lvl, log } from "../../utils/log";
import AssistantQA from "../../common/AssistantQA";
import { Conversation } from "../../data/generated/graphql";
import { UserContext } from "../../data/contexts/UserContext";
import "./AiAssistant.css";

const suggestedQuestion = [
  "Qu'est-ce-qu'une GTB ou une GTC ?",
  "Qu'est-ce qu'un système moto-régulé ?",
  "CEE, une opération standardisée c'est quoi ?",
  "Qu'est-ce que le SEQE et le PNAQ ?",
  "Air comprimé, quelles sont les opérations éligibles aux CEE ?",
  "Quels systèmes de ventilation mécanique sont éligibles aux subventions CEE ?",
  "A quoi sert un système de condensation frigorifique ?",
  "La loi POPE, c'est quoi ?",
];

const convGetState = (conv: Conversation): string =>
  conv.qas
    ?.map(
      (qa) =>
        `${qa.id}|${typeof qa.answer === "undefined" ? "n" : "y"}|${typeof qa.longAnswer === "undefined" ? "n" : "y"}`,
    )
    .join(";") || "";

const convHasChanged = (newConv?: Conversation, oldConv?: Conversation): boolean => {
  if (typeof newConv === "undefined") return false;
  if (typeof oldConv === "undefined") return true;
  if (newConv.id !== oldConv.id) return true;
  const newQAs = convGetState(newConv);
  const oldQAs = convGetState(oldConv);
  return oldQAs !== newQAs;
};

const AiAssistant: FC = (): JSX.Element => {
  const { t } = useTranslation(["aiAssistant", "global"]);
  const [question, setQuestion] = useState("");
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [asking, setAsking] = useState(false);
  const [firstQuestionIsLoading, setFirstQuestionIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const {
    askQuestion,
    currentConversation,
    openGreeny,
    setOpenGreeny,
    isAssistantActive,
    openGreenySubscription,
    setOpenGreenySubscription,
    setCurrentConversation,
  } = useContext(AssistantContext);
  const { userInfo } = useContext(UserContext);
  const openAssistantSubcription = (): void => setOpenGreenySubscription(!openGreenySubscription);

  const messagesBottom = useRef<HTMLDivElement | null>();
  const scrollToBottom = (): void => {
    if (messagesBottom.current) messagesBottom.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
  };

  const convRef = useRef<Conversation | undefined>(currentConversation);
  const isFirstQuestion = typeof currentConversation === "undefined";

  useEffect(() => {
    const shuffled = [...suggestedQuestion];
    shuffled.sort(() => (Math.random() > 0.5 ? 1 : -1));
    setSuggestions(shuffled.slice(0, 3));
  }, []);

  useEffect(() => {
    if (convHasChanged(currentConversation, convRef.current)) scrollToBottom();
    convRef.current = currentConversation;
  }, [currentConversation]);

  const sendQuestion = async (q: string): Promise<void> => {
    setAsking(true);
    if (isFirstQuestion) {
      setFirstQuestionIsLoading(true);
      scrollToBottom();
    }
    try {
      await askQuestion(q);
      setQuestion("");
    } catch (e) {
      log(e, Lvl.ERROR);
      enqueueSnackbar({ message: t("global:unkownError"), variant: "error" });
    }
    setAsking(false);
    setFirstQuestionIsLoading(false);
  };

  const canWithdraw = Boolean(userInfo?.assistantSubscription?.canWithdraw);

  return (
    <div className={openGreeny ? "aiContainer" : "aiContainer hidden"}>
      <IconButton
        sx={{
          color: Theme.palette.primary.main,
          position: "absolute",
          top: "20px",
          right: "20px",
          background: "white",
          boxShadow: "3px 3px 16px 2px rgba(0,0,0,0.28)",
          zIndex: "42",
        }}
        onClick={() => setOpenGreeny(false)}>
        <Close />
      </IconButton>
      {typeof currentConversation !== "undefined" ? (
        <Tooltip title={t("newConversation")} arrow>
          <IconButton
            className="circle"
            color="info"
            sx={{
              color: Theme.palette.primary.main,
              position: "absolute",
              top: "20px",
              left: "20px",
              background: "white",
              boxShadow: "3px 3px 16px 2px rgba(0,0,0,0.28)",
            }}
            disabled={typeof currentConversation === "undefined"}
            onClick={() => {
              setCurrentConversation(undefined);
              setQuestion("");
            }}>
            <Replay color="action" />
          </IconButton>
        </Tooltip>
      ) : undefined}
      <div className="content">
        <div className="header">
          <img
            width={250}
            src="https://greendeed.blob.core.windows.net/static/greeny-logo-gradient.png"
            alt="Logo Greeny"
          />
          <h3 className="title">{t("title")}</h3>
          <Typography className="subtitle">{t("subtitle")}</Typography>
          {canWithdraw ? (
            <Typography className="subtitle margin-top">
              {t("subscription.youCanWithdraw")}
              <Link to="/profile">{t("subscription.yourProfile")}</Link>
            </Typography>
          ) : null}
          <div className="suggestedQuestionsContainer">
            {suggestions.map((sq) => (
              <Button
                className="suggestedQuestion"
                key={sq}
                variant="outlined"
                color="primary"
                disabled={!isAssistantActive}
                onClick={() => {
                  sendQuestion(sq);
                  setQuestion(sq);
                }}>
                {sq}
              </Button>
            ))}
          </div>
        </div>
        {isAssistantActive ? (
          <div className="conversation">
            {firstQuestionIsLoading ? (
              <Card className="qa-container" style={{ whiteSpace: "pre-line" }}>
                <div className="question">
                  <AccountCircle color="disabled" />
                  <Typography>{question}</Typography>
                </div>
                <div className="answer" style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                  <CircularProgress size={24} />
                </div>
              </Card>
            ) : (
              currentConversation?.qas?.map((qa, index) => (
                <AssistantQA
                  id={currentConversation.id}
                  qa={qa}
                  key={qa.date}
                  loading={asking}
                  inChat
                  isLastQuestion={index === (currentConversation.qas?.length || 1) - 1}
                />
              ))
            )}
            <div
              style={{ height: 0 }}
              ref={(e) => {
                messagesBottom.current = e;
              }}
            />
          </div>
        ) : (
          <div className="subscription">
            <Card className="subscription-overlay">
              <Typography variant="subtitle1" className="text-center big-margin-bottom">
                {t("aiAssistant:subscription.overlaySpeech")}
                {"\n"}
                <a href={t("aiAssistant:subscription.moreInfoLink")} target="_blank" rel="noreferrer">
                  {t("aiAssistant:subscription.overlayMoreInfo")}
                </a>
              </Typography>
              <GdButton label={t("aiAssistant:subscription.subscribe")} onClick={openAssistantSubcription} />
            </Card>
          </div>
        )}
      </div>
      <div
        className="inputContainer"
        style={{
          backgroundColor: Theme.palette.primary.main,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
        }}>
        <TextField
          value={asking ? "" : question}
          className="aiInput"
          variant="outlined"
          placeholder={t("placeHolder")}
          onChange={(e) => setQuestion(e.target.value)}
          multiline
          fullWidth
          disabled={asking || !isAssistantActive}
        />
        <div className="textAndButtonContainer">
          <Typography className="warning">{t("warningSpeech")}</Typography>
          {asking || question.length === 0 ? (
            <IconButton
              className={asking || question.length === 0 ? "" : "aiButton"}
              disabled
              onClick={() => sendQuestion(question)}
              color="secondary">
              <Send />
            </IconButton>
          ) : (
            <Tooltip title={t("button")} arrow>
              <IconButton
                className={asking || question.length === 0 ? "" : "aiButton"}
                onClick={() => sendQuestion(question)}
                color="secondary">
                <Send />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default AiAssistant;
