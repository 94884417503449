/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import { IMaskInput } from "react-imask";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const makeTextMask = (
  mask: string,
  unmask?: boolean,
  capitalize?: boolean,
): ForwardRefExoticComponent<CustomProps & RefAttributes<HTMLElement>> =>
  forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        // @ts-ignore
        mask={mask}
        unmask={unmask}
        definitions={{
          "#": /[1-9]/,
        }}
        // @ts-ignore
        inputRef={ref}
        onAccept={(value: unknown) =>
          onChange({
            target: { name: props.name, value: capitalize ? (value as string).toUpperCase() : (value as string) },
          })
        }
        overwrite
      />
    );
  });

export const TextMaskSiret = makeTextMask("000 000 000 00000", true);

export const TextMaskSiren = makeTextMask("000 000 000", true);

export const TextMaskZipCode = makeTextMask("00000", true);

export const TextMaskActivity = makeTextMask("00.00a");

export const TextMaskRegistrationNumber = makeTextMask("aa-000-aa", undefined, true);

export const TextMaskNumber: ForwardRefExoticComponent<CustomProps & RefAttributes<HTMLElement>> = forwardRef<
  HTMLElement,
  CustomProps
>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      // @ts-ignore
      mask={Number}
      thousandsSeparator=" "
      scale={3}
      radix="."
      unmask
      // @ts-ignore
      inputRef={ref}
      onAccept={(value: unknown) => onChange({ target: { name: props.name, value: value as string } })}
      overwrite
    />
  );
});
