import { Form } from "../formsTypes";

const cdpForms: Partial<Form>[] = [
  {
    id: "BAT-TH-113",
    fields: [
      {
        id: "replacement",
        type: "choice",
        title: "S'agit-il d'un remplacement de la chaudière ?",
        skipValue: true,
        choices: [
          { name: "Oui", value: "1" },
          { name: "Non", value: "2" },
        ],
      },
      {
        id: "pacType",
        type: "choice",
        title: "Type de la pompe à chaleur",
        showIf: {
          fieldId: "replacement",
          fieldValues: ["1"],
        },
        choices: [
          { name: "air/eau", value: "1" },
          { name: "eau/eau", value: "2" },
        ],
      },
      {
        id: "energy",
        type: "choice",
        title: "Type de chaudière",
        showIf: {
          fieldId: "pacType",
          fieldValues: ["1"],
        },
        choices: [
          { name: "Gaz", value: "3" },
          { name: "Charbon / Fioul", value: "4" },
        ],
      },
      {
        id: "energy",
        type: "value",
        title: "Type de chaudière",
        showIf: {
          fieldId: "pacType",
          fieldValues: ["2"],
        },
        value: "5",
      },
    ],
  },
  {
    id: "BAT-TH-157",
    fields: [
      {
        id: "replacement",
        type: "choice",
        title: "S'agit-il d'un remplacement de la chaudière ?",
        skipValue: true,
        choices: [
          { name: "Oui", value: "1" },
          { name: "Non", value: "2" },
        ],
      },
      {
        id: "energy",
        type: "choice",
        title: "Type de chaudière",
        showIf: {
          fieldId: "replacement",
          fieldValues: ["1"],
        },
        choices: [
          { name: "Gaz", value: "3" },
          { name: "Charbon / Fioul", value: "4" },
        ],
      },
    ],
  },
  {
    id: "BAT-TH-127",
    fields: [
      {
        id: "replacement",
        type: "choice",
        title: "S'agit-il d'un remplacement de la chaudière ?",
        skipValue: true,
        choices: [
          { name: "Oui", value: "1" },
          { name: "Non", value: "2" },
        ],
      },
      {
        id: "formula",
        title: "Montants en kWh cumac par kW",
        type: "formula",
        showIf: {
          fieldId: "replacement",
          fieldValues: ["1"],
        },
        value: "BATTH127",
      },
    ],
    attachments: [],
  },
  {
    id: "BAT-TH-140",
    fields: [
      {
        id: "replacement",
        type: "choice",
        title: "S'agit-il d'un remplacement de la chaudière ?",
        skipValue: true,
        choices: [
          { name: "Oui", value: "1" },
          { name: "Non", value: "2" },
        ],
      },
      {
        id: "energy",
        type: "choice",
        title: "Type de chaudière",
        showIf: {
          fieldId: "replacement",
          fieldValues: ["1"],
        },
        choices: [
          { name: "Gaz", value: "1.3" },
          { name: "Charbon / Fioul", value: "1" },
        ],
      },
    ],
  },
  {
    id: "BAT-TH-141",
    fields: [
      {
        id: "replacement",
        type: "choice",
        title: "S'agit-il d'un remplacement de la chaudière ?",
        skipValue: true,
        choices: [
          { name: "Oui", value: "1" },
          { name: "Non", value: "2" },
        ],
      },
      {
        id: "energy",
        type: "choice",
        title: "Type de chaudière",
        showIf: {
          fieldId: "replacement",
          fieldValues: ["1"],
        },
        choices: [
          { name: "Gaz", value: "1" },
          { name: "Charbon / Fioul", value: "2" },
        ],
      },
    ],
  },
  {
    id: "IND-UT-137",
    fields: [
      {
        id: "temperature",
        type: "choice",
        title: "La température du condenseur est-elle supérieure à 70°C",
        choices: [
          {
            name: "Oui",
            value: "2",
          },
          {
            name: "Non",
            value: "1",
          },
        ],
      },
    ],
  },
  {
    id: "IND-UT-138",
    fields: [
      {
        id: "type",
        type: "value",
        title: "Toute la fiche",
        value: "2",
      },
    ],
  },
  {
    id: "IND-UT-139",
    fields: [
      {
        id: "type",
        type: "value",
        title: "Toute la fiche",
        value: "2",
      },
    ],
  },
  {
    id: "TRA-EQ-128",
    fields: [
      {
        id: "type",
        type: "value",
        title: "Toute la fiche",
        value: "4",
      },
    ],
  },
  {
    id: "TRA-SE-116",
    fields: [
      {
        id: "type",
        type: "value",
        title: "Toute la fiche",
        value: "4",
      },
    ],
  },
  // TODO cdp based on operation questions
  {
    id: "TRA-EQ-114",
    fields: [
      {
        id: "type",
        type: "value",
        title: "Si véhicule utilitaire léger neuf",
        value: "4",
        showIf: {
          fieldId: "type",
          fieldValues: ["2"],
          fieldSource: "form",
        },
      },
    ],
  },
  {
    id: "TRA-EQ-117",
    fields: [
      {
        id: "type",
        type: "value",
        title: "Si véhicule utilitaire léger neuf",
        value: "4",
        showIf: {
          fieldId: "type",
          fieldValues: ["2"],
          fieldSource: "form",
        },
      },
    ],
  },
];

export default cdpForms;
