import { Divider, Typography } from "@mui/material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../data/contexts/UserContext";
import { dateConvertToString } from "../../data/dataConvertors";
import { AttachmentType, DocumentInfo, Maybe, Project, Request, ValidationStatus } from "../../data/generated/graphql";
import GdButton from "../../utils/GdButton";
import UploadFileButton from "../components/UploadFileButton";
import WarningCard from "../../utils/WarningCard";

interface BillingRequestProps {
  project: Project;
}

const BillingRequestButtons: FC<{
  projectId: string;
  billingRequest?: Maybe<Request>;
  billing?: Maybe<DocumentInfo>;
  userBilling?: boolean;
}> = ({ projectId, billingRequest, billing, userBilling }) => {
  const { t } = useTranslation("project");
  const { removeAction } = useContext(UserContext);
  return (
    <>
      <Typography variant="h6" className="text-center margin-bottom text-with-returns">
        {t(billing?.validation?.status === ValidationStatus.Accepted ? "billingConfirmed" : "billingRequestEmitted", {
          replace: {
            date: dateConvertToString(
              billing?.validation?.status === ValidationStatus.Accepted
                ? billing?.validation?.date
                : billingRequest?.creationDate,
            ),
          },
        })}
      </Typography>
      <GdButton
        className="margin-bottom"
        label={t(billing?.validation?.status === ValidationStatus.Accepted ? "dlBill" : "dlBillingRequest")}
        color={billing?.validation?.status === ValidationStatus.Accepted ? "primary" : "secondary"}
        onClick={() => {
          if (billing?.validation?.status === ValidationStatus.Accepted)
            window.open(`${billing?.url}?t=${new Date().getTime()}`);
          else if (billingRequest?.url) window.open(`${billingRequest?.url}?t=${new Date().getTime()}`);
        }}
      />
      {!billing || billing.validation?.status === ValidationStatus.Rejected || (billing && !billing.validation) ? (
        <div className="big-margin-top">
          <Typography variant="h6" className="text-center margin-bottom text-with-returns">
            {t(
              billing && !billing.validation
                ? "billValidationOngoing"
                : billing?.validation?.status === ValidationStatus.Rejected
                ? "billRejected"
                : "sendBill",
              { replace: { reason: billing?.validation?.comment } },
            )}
          </Typography>
          {billing && !billing.validation ? (
            <GdButton
              label={t("dlBill")}
              onClick={() => {
                if (billing?.url) window.open(`${billing?.url}?t=${new Date().getTime()}`);
              }}
            />
          ) : (
            <UploadFileButton
              projectId={projectId}
              label={t("ulBill")}
              type={userBilling ? AttachmentType.UserBilling : AttachmentType.Billing}
              onAfterUpload={() => removeAction(projectId)}
            />
          )}
        </div>
      ) : undefined}
    </>
  );
};

BillingRequestButtons.defaultProps = { billingRequest: undefined, billing: undefined, userBilling: false };

const BillingRequest: FC<BillingRequestProps> = ({ project }) => {
  const { t } = useTranslation("project");
  return (
    <>
      <BillingRequestButtons projectId={project.id} billingRequest={project.billingRequest} billing={project.billing} />

      {typeof project?.userBillingRequest?.creationDate !== "undefined" ? (
        <>
          <Divider sx={{ width: "100%", borderColor: "#000", margin: "24px 0" }} />
          <Typography variant="h5" className="text-center margin-bottom">
            {t("userBillingRequest")}
          </Typography>
          <WarningCard
            className="margin-bottom"
            bodyData={{
              titleLabelKey: "userBillingRequestWarningTitle",
              content: <span className="text-with-returns">{t("userBillingRequestWarningContent")}</span>,
            }}
            warning
          />
          <BillingRequestButtons
            projectId={project.id}
            billingRequest={project.userBillingRequest}
            billing={project.userBilling}
            userBilling
          />
        </>
      ) : undefined}
    </>
  );
};

export default BillingRequest;
