import { Edit } from "@mui/icons-material";
import { IconButton, Modal, Paper, Tab, Tabs, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { ProcedureStatus, Project, ProjectStatus, ValidationStatus } from "../data/generated/graphql";
import BaseCard from "../utils/BaseCard";
import CenteredContent from "../utils/CenteredContent";
import ProjectNameClient from "./components/NameClient";
import Attachments from "./tabs/Attachments";
import BillingRequest from "./tabs/BillingRequest";
import DetailsSelector from "./tabs/DetailsSelector";
import FormsSelector from "./tabs/FormsSelector";
import ProjectConvention from "./tabs/ProjectConvention";
import ResultViewer from "./tabs/ResultViewer";
import SignedQuote from "./tabs/SignedQuote";
import SwornStatement from "./tabs/SwornStatement";

import "./Project.css";

export interface ProjectsParams {
  projectId?: string;
  vehicleListId?: string;
}

interface ProjectViewerProps {
  project: Project;
}

const getTabsAllowed = (project: Project): string[] => {
  const result = ["details", "forms", "result"];
  if (
    project.convention?.status === ProcedureStatus.Finished &&
    project.quote?.validation?.status === ValidationStatus.Accepted
  ) {
    result.push("attachments");
    result.push("swornStatement");
    if (project.billingRequest) result.push("billingRequest");
  }
  if (project.status === ProjectStatus.Launched || Boolean(project.convention)) {
    result.push("convention");
    result.push("quote");
  }
  return result;
};

const isTabAllowed = (project: Project, pathname: string): boolean => {
  const pathParts = pathname.split("/");
  const tabName = pathParts[pathParts.length - 1];
  return getTabsAllowed(project).indexOf(tabName) !== -1;
};

const ProjectViewer: FC<ProjectViewerProps> = ({ project }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation("project");
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const { projectId } = useParams() as ProjectsParams;
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(0);

  const createUrl = (section: string): string => `${path.replace(":projectId", projectId || "")}/${section}`;
  const createOnClick = (section: string) => (event?: React.MouseEvent) => {
    if (event) event.preventDefault();
    history.push(createUrl(section));
  };

  useEffect(() => {
    // If there is only 2 sections of the path filled, we are on the section's root
    const isRootPath =
      pathname.split("/").filter((s: string) => s.length > 0).length === 2 || !isTabAllowed(project, pathname);
    if (isRootPath && project.id === projectId) {
      if (!project.details?.startDate || project.details?.startDate.length === 0) {
        // Details have not been defined yet
        history.replace(createUrl("details"));
      } else if (!project.operations || project.operations.length === 0) {
        // No operation has been selected yet
        history.replace(createUrl("forms"));
      } else if (project.status === ProjectStatus.Simulation) {
        // Everything seems ok, show results
        history.replace(createUrl("result"));
      } else if (project.billingRequest) {
        history.replace(createUrl("billingRequest"));
      } else if (
        project.attachments &&
        project.attachments.length > 0 &&
        project.attachments.findIndex((a) => a.validation?.status !== ValidationStatus.Accepted) === -1
      ) {
        history.replace(createUrl("swornStatement"));
      } else if (
        project.convention?.status === ProcedureStatus.Finished &&
        project.quote?.validation?.status === ValidationStatus.Accepted
      ) {
        history.replace(createUrl("attachments"));
      } else if (project.convention?.status === ProcedureStatus.Finished) {
        history.replace(createUrl("quote"));
      } else if (project.status === ProjectStatus.Launched) {
        history.replace(createUrl("convention"));
      }
    } else if (pathname.indexOf("/details") !== -1) setCurrentTab(0);
    else if (pathname.indexOf("/forms") !== -1) setCurrentTab(1);
    else if (pathname.indexOf("/result") !== -1) setCurrentTab(2);
    else if (pathname.indexOf("/convention") !== -1) setCurrentTab(3);
    else if (pathname.indexOf("/quote") !== -1) setCurrentTab(4);
    else if (pathname.indexOf("/attachments") !== -1) setCurrentTab(5);
    else if (pathname.indexOf("/swornStatement") !== -1) setCurrentTab(6);
    else if (pathname.indexOf("/billingRequest") !== -1) setCurrentTab(7);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, project]);

  const handleModalToggle = (open: boolean) => () => setModalOpen(open);
  const closeModal = (): void => setModalOpen(false);

  if (project.status === ProjectStatus.Canceled) return <CenteredContent errorText={t("projectHasBeenCanceled")} />;

  return (
    <>
      <BaseCard>
        <div className="project-card">
          <div className="project-header">
            <Typography variant="h6">
              {project.name} - ({t("clientTwo")} {project.client?.company?.name || ""})
            </Typography>
            {project.status !== ProjectStatus.Simulation || project.isReadOnly || currentTab !== 0 ? undefined : (
              <IconButton onClick={handleModalToggle(true)}>
                <Edit />
              </IconButton>
            )}
          </div>
          <div className="row project-tabs-root">
            <Tabs
              orientation="vertical"
              indicatorColor="primary"
              textColor="primary"
              sx={{ borderRight: 1, borderColor: "divider", height: "100%" }}
              value={currentTab}>
              <Tab label={t("tabs.details")} onClick={createOnClick("details")} href={createUrl("details")} />
              <Tab label={t("tabs.forms")} onClick={createOnClick("forms")} href={createUrl("forms")} />
              <Tab label={t("tabs.result")} onClick={createOnClick("result")} href={createUrl("result")} />
              {project.status !== ProjectStatus.Simulation
                ? [
                    <Tab
                      label={t("tabs.convention")}
                      onClick={createOnClick("convention")}
                      href={createUrl("convention")}
                      key="convention"
                    />,
                    <Tab
                      label={t("tabs.quote")}
                      onClick={createOnClick("quote")}
                      href={createUrl("quote")}
                      key="quote"
                    />,
                  ]
                : undefined}
              {project.convention?.status === ProcedureStatus.Finished &&
              project.quote?.validation?.status === ValidationStatus.Accepted
                ? [
                    <Tab
                      label={t("tabs.attachments")}
                      onClick={createOnClick("attachments")}
                      href={createUrl("attachments")}
                      key="attachments"
                    />,
                    <Tab
                      label={<span style={{ whiteSpace: "pre-line" }}>{t("tabs.swornStatement")}</span>}
                      onClick={createOnClick("swornStatement")}
                      href={createUrl("swornStatement")}
                      key="swornStatement"
                    />,
                  ]
                : undefined}
              {project.billingRequest ? (
                <Tab
                  label={<span style={{ whiteSpace: "pre-line" }}>{t("tabs.billingRequest")}</span>}
                  onClick={createOnClick("billingRequest")}
                  href={createUrl("billingRequest")}
                />
              ) : undefined}
            </Tabs>
            <div className="project-nav-root">
              <div className="project-section-root">
                <Switch>
                  <Route path={`${path}/details`}>
                    <DetailsSelector
                      project={project}
                      nextStep={createOnClick("forms")}
                      readOnly={project.status !== ProjectStatus.Simulation || project.isReadOnly}
                    />
                  </Route>
                  <Route path={`${path}/forms`}>
                    <FormsSelector
                      project={project}
                      nextStep={createOnClick("result")}
                      readOnly={project.status !== ProjectStatus.Simulation || project.isReadOnly}
                    />
                  </Route>
                  <Route path={`${path}/result`}>
                    <ResultViewer project={project} />
                  </Route>
                  <Route path={`${path}/convention`}>
                    <ProjectConvention project={project} />
                  </Route>
                  <Route path={`${path}/quote`}>
                    <SignedQuote project={project} />
                  </Route>
                  <Route path={`${path}/attachments`}>
                    <Attachments project={project} />
                  </Route>
                  <Route path={`${path}/swornStatement`}>
                    <SwornStatement project={project} />
                  </Route>
                  <Route path={`${path}/billingRequest`}>
                    <BillingRequest project={project} />
                  </Route>
                  <Route path={path}>
                    <CenteredContent loading />
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </BaseCard>
      <Modal open={modalOpen} onClose={closeModal} className="project-modal-root">
        <Paper className="project-modal-paper">
          <ProjectNameClient project={project} onClose={closeModal} />
        </Paper>
      </Modal>
    </>
  );
};

export default ProjectViewer;
