import { ApolloError } from "@apollo/client";
import { createContext, FC, PropsWithChildren, useContext, useState } from "react";
import ReactGa from "react-ga4";
import { log, Lvl } from "../../utils/log";
import {
  Article,
  AssistantSubscriptionInput,
  Claims,
  Project,
  SubProject,
  useArticleGetAllQuery,
  useProjectGetUserActionsQuery,
  User,
  UserAction,
  UserType,
  UserUpdate,
  useUserCheckAssistantSubscriptionMutation,
  useUserCheckCharterMutation,
  useUserCreateMeMutation,
  useUserMeQuery,
  useUserUpdateMutation,
  useUserWithdrawAssistantSubscriptionMutation,
} from "../generated/graphql";
import { ProjectContext } from "./ProjectContext";
import { SubProjectContext } from "./SubProjectContext";

export interface UserContextProps {
  userInfo: User | null;
  getUser: () => Promise<void>;
  createUser: (userType: UserType) => Promise<boolean>;
  updateUser: (user: UserUpdate) => Promise<User | null>;
  checkCharter: (forcePrepare: boolean) => Promise<User | null>;
  deleteUserInfo: () => void;
  loading: boolean;
  error?: ApolloError;
  mutationError?: ApolloError;
  updateError?: ApolloError;
  checkCharterError?: ApolloError;
  articles: Article[];
  getArticles: () => Promise<boolean>;
  actions: UserAction[];
  removeAction: (projectId: string) => void;
  hasClaim: (name: Claims) => boolean;
  checkAssistantSubscription: (
    input: AssistantSubscriptionInput,
    phoneNumber: string,
    forcePrepare?: boolean,
  ) => Promise<boolean>;
  withdrawAssistantSubscription: () => Promise<boolean>;
}

const initialContext: UserContextProps = {
  userInfo: null,
  getUser: () => Promise.resolve(),
  createUser: () => Promise.resolve(false),
  updateUser: () => Promise.resolve(null),
  checkCharter: () => Promise.resolve(null),
  deleteUserInfo: () => {},
  loading: false,
  articles: [],
  getArticles: () => Promise.resolve(false),
  actions: [],
  removeAction: () => {},
  hasClaim: () => false,
  checkAssistantSubscription: () => Promise.resolve(false),
  withdrawAssistantSubscription: () => Promise.resolve(false),
};

export const UserContext = createContext<UserContextProps>(initialContext);

export const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const [userInfo, setUserInfo] = useState<User | null>(null);
  const [articles, setArticles] = useState<Article[]>([]);
  const [actions, setActions] = useState<UserAction[]>([]);
  const [latestArticlesCheck, setLatestArticlesCheck] = useState(0);
  const { loading, error, refetch } = useUserMeQuery({ skip: true });
  const [userCreateMe, { error: mutationError }] = useUserCreateMeMutation({
    errorPolicy: "all",
  });
  const [userUpdate, { error: updateError }] = useUserUpdateMutation({ errorPolicy: "all" });
  const [userCheckCharter, { error: checkCharterError }] = useUserCheckCharterMutation({ errorPolicy: "all" });
  const { refetch: articleGetAll } = useArticleGetAllQuery({ skip: true });
  const { refetch: actionsGet } = useProjectGetUserActionsQuery({ skip: true });
  const { saveProjects } = useContext(ProjectContext);
  const [userCheckAssistantSubscription] = useUserCheckAssistantSubscriptionMutation({ errorPolicy: "all" });
  const [userWithdrawAssistantSubscription] = useUserWithdrawAssistantSubscriptionMutation({ errorPolicy: "all" });
  const { saveSubProjects } = useContext(SubProjectContext);

  const getUser = async (): Promise<void> => {
    const result = await refetch();
    if (result && result.data) {
      setUserInfo(result.data.userMe?.user || null);
      try {
        ReactGa.set({ userId: result.data.userMe?.user?.id });
      } catch (err) {
        log("Analytics set user id failed", Lvl.ERROR, err);
      }
      // Get user actions
      const r = await actionsGet();
      setActions(r.data.projectGetUserActions || []);
      saveProjects(r.data.projectGetMine as Project[]);
      saveSubProjects(r.data.subprojectGetMyFleetSubProjects as SubProject[]);
    }
  };

  const createUser = async (userType: UserType): Promise<boolean> => {
    const result = await userCreateMe({ variables: { userType } });
    if (result.data?.userCreateMe) {
      setUserInfo(result.data?.userCreateMe?.user || null);
      return true;
    }
    return false;
  };

  const updateUser = async (user: UserUpdate): Promise<User | null> => {
    const result = await userUpdate({ variables: { user } });
    if (result.data?.userUpdate) {
      setUserInfo(result.data?.userUpdate);
      return result.data?.userUpdate;
    }
    return null;
  };

  const checkCharter = async (forcePrepare: boolean): Promise<User | null> => {
    const result = await userCheckCharter({ variables: { forcePrepare } });
    if (result.data?.userCheckCharter) {
      setUserInfo(result.data?.userCheckCharter);
      return result.data?.userCheckCharter;
    }
    return null;
  };

  const deleteUserInfo = (): void => {
    setUserInfo(null);
  };

  const getArticles = async (): Promise<boolean> => {
    if (latestArticlesCheck < new Date().getTime() - 15 * 60 * 1000) {
      const result = await articleGetAll();
      if (result.data.articleGetAll) {
        setLatestArticlesCheck(new Date().getTime());
        setArticles(result.data.articleGetAll || []);
        return true;
      }
      return false;
    }
    return true;
  };

  const removeAction = (projectId: string): void => {
    setActions(actions.filter((a) => a.projectId !== projectId));
  };

  const hasClaim = (name: Claims): boolean => {
    if (userInfo) {
      return userInfo.claims?.find((c) => c.name === name)?.granted || false;
    }
    return false;
  };

  const checkAssistantSubscription = async (
    input: AssistantSubscriptionInput,
    phoneNumber: string,
    forcePrepare?: boolean,
  ): Promise<boolean> => {
    const result = await userCheckAssistantSubscription({
      variables: { assistantSubscription: input, phoneNumber, forcePrepare },
    });
    if (result.data?.userCheckAssistantSubscription) {
      setUserInfo(result.data?.userCheckAssistantSubscription);
      return true;
    }
    return false;
  };

  const withdrawAssistantSubscription = async (): Promise<boolean> => {
    const result = await userWithdrawAssistantSubscription();
    if (result.data?.userWithdrawAssistantSubscription) {
      setUserInfo(result.data?.userWithdrawAssistantSubscription);
      return true;
    }
    return false;
  };

  return (
    <UserContext.Provider
      value={{
        userInfo,
        getUser,
        createUser,
        deleteUserInfo,
        loading,
        error,
        mutationError,
        updateUser,
        updateError,
        checkCharter,
        checkCharterError,
        articles,
        getArticles,
        actions,
        removeAction,
        hasClaim,
        checkAssistantSubscription,
        withdrawAssistantSubscription,
      }}>
      {children}
    </UserContext.Provider>
  );
};
