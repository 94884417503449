import { Add, AddCircle, ContentCopy, Edit, Visibility } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { dateConvertToString, vehicleToVehicleInput } from "../../data/dataConvertors";
import { getTraEq114 } from "../../data/forms/FormHelper";
import {
  AttachmentType,
  ValidationStatus,
  Vehicle,
  VehicleInput,
  VehicleList,
  VehicleStatus,
} from "../../data/generated/graphql";
import CenteredContent from "../../utils/CenteredContent";
import GdButton from "../../utils/GdButton";
import UploadFileButton from "../components/UploadFileButton";
import VehicleModal from "./VehicleModal";
import CompanyModal from "./companyModal/CompanyModal";

interface VehiclesGridProps {
  vehicleList: VehicleList;
  userIsRaf: boolean;
  subProjectId: string;
}

const columnWidths = [
  "20%", // Société
  "20%", // Date de première immatriculation
  "20%", // Numéro d'immatriculation du véhicule
  "20%", // Catégorie du véhicule
];

const VehiclesGrid: FC<VehiclesGridProps> = ({ vehicleList, userIsRaf, subProjectId }) => {
  const { t } = useTranslation("vehicles");
  const [openModal, setOpenModal] = useState(false);
  const [openCompanyModal, setOpenCompanyModal] = useState(false);
  const [vehiculeToModify, setVehiculeToModify] = useState<VehicleInput | undefined>(undefined);
  const [statusFilter, setStatusFilter] = useState("");
  const [isCreation, setIsCreation] = useState(true);
  const form = getTraEq114();
  const getVehicleType = (type?: number): string =>
    form.fields.find((f) => f.id === "type")?.choices?.find((c) => c.value === type?.toString())?.name || "";
  const modifyVehicle = (vehicle: Vehicle): void => {
    setVehiculeToModify(vehicleToVehicleInput(vehicle));
    setIsCreation(false);
    setOpenModal(true);
  };
  const createVehicle = (): void => {
    setIsCreation(true);
    setOpenModal(true);
  };
  const vehicles = vehicleList.vehicles || [];
  const header = [
    t("registrationNumber"),
    t("registrationDate"),
    t("vehicleType"),
    t("status.status"),
    t("proof"),
    t("registration"),
    t("reception"),
  ];
  const delegateLink = `${window.location.origin}/enrollAsFleetManager/${vehicleList.id}`;

  const filteredVehicleList = vehicleList.vehicles?.filter((v) =>
    statusFilter === "all" || statusFilter.length === 0 ? true : v.status === statusFilter,
  );
  const copylink = (): void => {
    navigator.clipboard.writeText(delegateLink).then(
      () => {
        enqueueSnackbar(t("linkIsInClipboard"), { variant: "success" });
      },
      () => {
        enqueueSnackbar(t("couldNotWriteClipboard"), { variant: "error" });
      },
    );
  };

  const copyLinkButton = <GdButton onClick={copylink} label={t("copyDelegateLink")} />;
  return (
    <div style={{ padding: "0 0 0 24px" }}>
      {vehicles.length === 0 ? (
        <Stack direction="column" className="project-section-root" justifyContent="center" gap="32px">
          <Typography variant="h6" textAlign="center">
            {t("noVehicleYet")}
          </Typography>
          {userIsRaf ? copyLinkButton : undefined}
          {userIsRaf ? <GdButton label={t("modifyCompany")} onClick={() => setOpenCompanyModal(true)} /> : undefined}
          <GdButton label={t("addFirstVehicle")} onClick={() => createVehicle()} />
        </Stack>
      ) : (
        <>
          <Stack direction="row" justifyContent="space-between" padding="16px 0">
            <Stack direction="row" alignItems="center" gap="16px">
              <Typography>{t("status.title")}</Typography>
              <FormControl margin="none">
                <InputLabel>{t("status.status")}</InputLabel>
                <Select
                  sx={{ width: "200Px" }}
                  label={t("status.status")}
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}>
                  <MenuItem value="all">{t(`status.all`)}</MenuItem>
                  {Object.values(VehicleStatus).map((vs) => (
                    <MenuItem value={vs}>{t(`status.${vs}`)}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack direction="row" justifyContent="flex-end">
              <Stack direction="row" alignSelf="center">
                {userIsRaf ? (
                  <>
                    <Tooltip title={t("modifyCompany") as string} arrow>
                      <IconButton onClick={() => setOpenCompanyModal(true)}>
                        <Edit color="primary" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t("copyDelegateLink") as string} arrow>
                      <IconButton onClick={copylink}>
                        <ContentCopy color="primary" />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : undefined}
                <Tooltip title={t("addVehicle") as string} arrow>
                  <IconButton onClick={() => createVehicle()} color="primary" size="large">
                    <Add />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          </Stack>
          {typeof filteredVehicleList !== "undefined" && filteredVehicleList?.length > 0 ? (
            <TableContainer component={Paper} sx={{ width: "950px" }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {header.map((column, index) => (
                      <TableCell
                        key={column}
                        sx={{ borderRight: index === header.length - 1 ? undefined : "1px solid #EEE" }}>
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredVehicleList?.map((vehicle) => (
                    <TableRow
                      key={vehicle.registrationNumber}
                      onClick={() => modifyVehicle(vehicle)}
                      style={{ cursor: "pointer" }}
                      role="button"
                      tabIndex={0}
                      onKeyPress={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          modifyVehicle(vehicle);
                        }
                      }}>
                      <TableCell style={{ width: columnWidths[2] }}>{vehicle.registrationNumber}</TableCell>
                      <TableCell style={{ width: columnWidths[1] }}>
                        {vehicle.registrationDate ? dateConvertToString(vehicle.registrationDate) : ""}
                      </TableCell>
                      <TableCell style={{ width: columnWidths[3] }}>
                        {getVehicleType(vehicle.type || undefined)}
                      </TableCell>
                      <TableCell style={{ width: columnWidths[0] }}>
                        {t(`status.${vehicle.status}`) || undefined}
                      </TableCell>
                      <TableCell
                        style={{ width: columnWidths[0], cursor: "default" }}
                        onClick={(e) => e.stopPropagation()}>
                        <Stack direction="row" justifyContent="center" alignItems="center">
                          {vehicle.proof ? (
                            <>
                              {vehicle.proof.validation?.status === ValidationStatus.Accepted ? undefined : (
                                <UploadFileButton
                                  buttonId={`proof_${vehicle.id}`}
                                  label={t("proof")}
                                  type={AttachmentType.VehicleProof}
                                  projectId={subProjectId}
                                  vehicleId={vehicle.id}
                                  vehicleListId={vehicleList.id}
                                  icon={<Edit />}
                                  color="primary"
                                  tooltipTitle={t("modifyProof")}
                                />
                              )}
                              <Tooltip title={t("viewProof")}>
                                <IconButton onClick={() => window.open(vehicle.proof?.url)}>
                                  <Visibility color="secondary" />
                                </IconButton>
                              </Tooltip>
                            </>
                          ) : (
                            <UploadFileButton
                              buttonId={`proof_${vehicle.id}`}
                              label={t("proof")}
                              type={AttachmentType.VehicleProof}
                              projectId={subProjectId}
                              vehicleId={vehicle.id}
                              vehicleListId={vehicleList.id}
                              icon={<AddCircle />}
                              color="primary"
                              tooltipTitle={t("addProof")}
                            />
                          )}
                        </Stack>
                      </TableCell>
                      <TableCell
                        style={{ width: columnWidths[0], cursor: "default" }}
                        onClick={(e) => e.stopPropagation()}>
                        <Stack direction="row" justifyContent="center">
                          {vehicle.registration ? (
                            <>
                              {vehicle.registration.validation?.status === ValidationStatus.Accepted ? undefined : (
                                <UploadFileButton
                                  buttonId={`registration_${vehicle.id}`}
                                  label={t("registration")}
                                  type={AttachmentType.VehicleRegistration}
                                  projectId={subProjectId}
                                  vehicleId={vehicle.id}
                                  vehicleListId={vehicleList.id}
                                  icon={<Edit />}
                                  color="primary"
                                  tooltipTitle={t("modifyRegistration")}
                                />
                              )}
                              <Tooltip title={t("viewRegistration")}>
                                <IconButton onClick={() => window.open(vehicle.registration?.url)}>
                                  <Visibility color="secondary" />
                                </IconButton>
                              </Tooltip>
                            </>
                          ) : (
                            <UploadFileButton
                              buttonId={`registration_${vehicle.id}`}
                              label={t("registration")}
                              type={AttachmentType.VehicleRegistration}
                              projectId={subProjectId}
                              vehicleId={vehicle.id}
                              vehicleListId={vehicleList.id}
                              icon={<AddCircle />}
                              color="primary"
                              tooltipTitle={t("addRegistration")}
                            />
                          )}
                        </Stack>
                      </TableCell>
                      <TableCell
                        style={{ width: columnWidths[0], cursor: "default" }}
                        onClick={(e) => e.stopPropagation()}>
                        <Stack direction="row" justifyContent="center">
                          {vehicle.reception ? (
                            <>
                              {vehicle.reception.validation?.status === ValidationStatus.Accepted ? undefined : (
                                <UploadFileButton
                                  buttonId={`reception${vehicle.id}`}
                                  label={t("reception")}
                                  type={AttachmentType.VehicleReception}
                                  projectId={subProjectId}
                                  vehicleId={vehicle.id}
                                  vehicleListId={vehicleList.id}
                                  icon={<Edit />}
                                  color="primary"
                                  tooltipTitle={t("modifyReception")}
                                />
                              )}
                              <Tooltip title={t("viewReception")}>
                                <IconButton onClick={() => window.open(vehicle.reception?.url)}>
                                  <Visibility color="secondary" />
                                </IconButton>
                              </Tooltip>
                            </>
                          ) : (
                            <UploadFileButton
                              buttonId={`reception${vehicle.id}`}
                              label={t("reception")}
                              type={AttachmentType.VehicleReception}
                              projectId={subProjectId}
                              vehicleId={vehicle.id}
                              vehicleListId={vehicleList.id}
                              icon={<AddCircle />}
                              color="primary"
                              tooltipTitle={t("addReception")}
                            />
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Stack width="950px" height="250px">
              <CenteredContent infoText={t("noItemForStatus")} />
            </Stack>
          )}
        </>
      )}
      <CompanyModal
        open={openCompanyModal}
        onClose={() => setOpenCompanyModal(false)}
        subProjectId={subProjectId}
        signerToBeUpdated={vehicleList.signer}
        vehicleListId={vehicleList.id}
      />
      <VehicleModal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setVehiculeToModify(undefined);
        }}
        vehicleToModify={vehiculeToModify}
        vehicleList={vehicleList}
        isCreation={isCreation}
      />
    </div>
  );
};
export default VehiclesGrid;
