import { ExpandMore, InsertDriveFile } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Card, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import BaseCard from "../utils/BaseCard";
import agriForms from "../data/forms/data/agriForms";
import batForms from "../data/forms/data/batForms";
import indForms from "../data/forms/data/indForms";
import traForms from "../data/forms/data/traForms";
import { Form } from "../data/forms/formsTypes";

enum CatalogSector {
  Agri = "AGRI",
  Bat = "BAT",
  Ind = "IND",
  Tra = "TRA",
}

const Forms: FC = () => {
  const { t } = useTranslation("global");
  const forms = useMemo((): Record<CatalogSector, Form[]> => {
    const tmpForms = {
      TRA: traForms,
      AGRI: agriForms,
      BAT: batForms,
      IND: indForms,
    };
    return tmpForms;
  }, []);

  return (
    <BaseCard>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "12px",
          gap: "12px",
        }}>
        <Typography align="center" variant="h5" fontWeight={400}>
          {t("titles./forms")}
        </Typography>
        <div style={{ width: "100%" }}>
          {Object.values(CatalogSector).map((sector) => (
            <Accordion key={sector} sx={{ padding: "12px", width: "100%" }}>
              <AccordionSummary sx={{ width: "100%" }} expandIcon={<ExpandMore />}>
                <Typography variant="h6">{`${sector} : (${forms[sector as CatalogSector].length})`}</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "flex-start", gap: "12px" }}>
                {forms[sector as CatalogSector].map((form) => (
                  <Card
                    key={form.id}
                    onClick={() => window.open(form.formUrl || "")}
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "12px",
                      width: "310px",
                    }}>
                    <Typography variant="h6" sx={{ fontWeight: "500" }}>
                      {form.id}
                    </Typography>
                    <Typography sx={{ fontWeight: "500" }} align="center">
                      {form.name}
                    </Typography>
                    <InsertDriveFile width={32} height={32} color="primary" />
                  </Card>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    </BaseCard>
  );
};

export default Forms;
