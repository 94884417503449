import { FormControl, InputLabel, OutlinedInput, Stack } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectContext } from "../../../data/contexts/ProjectContext";
import { companyToCompanyInput } from "../../../data/dataConvertors";
import { CompanyInput, SignerInput } from "../../../data/generated/graphql";
import CenteredContent from "../../../utils/CenteredContent";
import GdButton from "../../../utils/GdButton";
import { log, Lvl } from "../../../utils/log";
import { TextMaskSiret } from "../../../utils/TextMasks";

interface SiretStepProps {
  signer: SignerInput;
  setSigner: (signer: SignerInput) => void;
  setStep: (step: number) => void;
}
const SiretStep: FC<SiretStepProps> = ({ signer, setSigner, setStep }) => {
  const { t } = useTranslation(["vehicles", "project"]);
  const { getCompanyFromSiret } = useContext(ProjectContext);
  const [isLoading, setIsLoading] = useState(false);

  const onCompanyFetched = (company: CompanyInput): void => {
    const newSigner = {
      ...signer,
      company: companyToCompanyInput({ ...signer?.company }),
    };
    const fetchedCompanyProps = Object.keys(company);
    fetchedCompanyProps.forEach((key) => {
      if (key.startsWith("_") || (company[key as keyof CompanyInput]?.length || 0) === 0 || !newSigner.company) return;
      newSigner.company[key as keyof CompanyInput] = company[key as keyof CompanyInput] as string;
    });
    setSigner(newSigner);
  };

  const handleFetch = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const company = await getCompanyFromSiret(signer.company.siret);
      if (company) {
        onCompanyFetched(companyToCompanyInput(company));
        setStep(2);
      } else {
        enqueueSnackbar(t("project:companyNotFound"), { variant: "error" });
      }
    } catch (err) {
      log("Company fetch failed", Lvl.ERROR, err);
      enqueueSnackbar(
        t((err as Error).message === "Siret not found" ? "project:companyNotFound" : "project:inseeDown"),
        {
          variant: "error",
        },
      );
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Stack direction="column" gap="16px" justifyContent="center">
      <FormControl fullWidth>
        <InputLabel htmlFor="company-siret-input">{t("project:siret")}</InputLabel>
        <OutlinedInput
          id="company-siret-input"
          label={t("project:siret")}
          value={signer.company?.siret || ""}
          onChange={(e) => setSigner({ ...signer, company: { ...signer.company, siret: e.target.value } })}
          inputComponent={TextMaskSiret as any}
          autoComplete="off"
        />
      </FormControl>
      <Stack direction="row" gap="16px" justifyContent="center" marginTop="16px">
        {isLoading ? (
          <CenteredContent loading loadingSize={20} />
        ) : (
          <GdButton disabled={signer.company.siret.length !== 14} label={t("next")} onClick={handleFetch} />
        )}
      </Stack>
    </Stack>
  );
};

export default SiretStep;
