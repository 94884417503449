import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-common";
import { InteractionStatus } from "@azure/msal-browser";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { FC, useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navigation from "./navigation/Navigation";
import Unknown from "./auth/Unknown/Unknown";
import { log, Lvl } from "./utils/log";
import { UserContext } from "./data/contexts/UserContext";
import { getCurrentTenantId, MSAL_DEFAULT_SCOPES } from "./auth/authConfig";
import CenteredContent from "./utils/CenteredContent";
import { TokenContext } from "./data/contexts/TokenContext";
import Signature from "./public/Signature";
import SigningFinished from "./public/SigningFinished";
import AnalyticsWrapper from "./navigation/AnalyticsWrapper";

import "./App.css";

const App: FC = () => {
  const [loading, setLoading] = useState(true);
  const [unauthorized, setUnauthorized] = useState(false);
  const [error, setError] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();
  const tenantId = getCurrentTenantId();
  const relevantAccount = accounts?.find((a) => a.tenantId === tenantId) || {};
  const account = useAccount(relevantAccount);
  const { loading: userLoading, getUser, userInfo } = useContext(UserContext);
  const { setAuthToken } = useContext(TokenContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("global");

  const logOut = (): void => {
    setLoading(true);
    // Logout
    instance.logout();
  };

  const forceRetryLogin = (): void => {
    instance.acquireTokenRedirect(MSAL_DEFAULT_SCOPES);
  };

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      if (isAuthenticated && account) {
        // Check token
        const checkToken = async (): Promise<void> => {
          try {
            setLoading(true);
            const response = await instance.acquireTokenSilent({ ...MSAL_DEFAULT_SCOPES, account });
            if (response) {
              setAuthToken(response.accessToken, response.expiresOn);
              // Let the token propagate to GdApolloClient...
              setTimeout(async () => {
                try {
                  await getUser();
                  setLoading(false);
                } catch (err) {
                  const e = Array.isArray(err) ? (err[0] as Error) : (err as Error);
                  setLoading(false);
                  log("Get User failed", Lvl.ERROR, e);
                  if (e.message.indexOf("Not found") !== -1) {
                    setUnauthorized(false);
                  } else if (e.message.indexOf("Unauthorized") !== -1) {
                    setUnauthorized(true);
                  } else {
                    setError(true);
                  }
                }
              }, 200);
            }
          } catch (err) {
            if (
              err instanceof InteractionRequiredAuthError ||
              (err as Error).message.toLowerCase().includes("login_required") ||
              (err as Error).message.toLowerCase().includes("interaction_required") ||
              (err as Error).message.toLowerCase().includes("interactionrequiredautherror")
            ) {
              // fallback to interaction when silent call fails
              instance.acquireTokenRedirect(MSAL_DEFAULT_SCOPES);
            } else {
              setLoading(false);
              log(err, Lvl.ERROR);
              enqueueSnackbar(t("unkownError"), { variant: "error" });
              setError(true);
            }
          }
        };
        checkToken();
      } else {
        // User is anonymous
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, inProgress]);

  const authenticatedBody =
    userInfo === null ? (
      <Unknown
        error={error}
        anonymous={false}
        userName={account ? account.name : ""}
        unauthorized={unauthorized}
        forceRetryLogin={forceRetryLogin}
      />
    ) : (
      <Router>
        <AnalyticsWrapper>
          <Navigation signOut={logOut} />
        </AnalyticsWrapper>
      </Router>
    );

  return (
    <div className="App">
      {loading ? (
        <CenteredContent loading />
      ) : (
        <>
          <AuthenticatedTemplate>{userLoading ? <CenteredContent loading /> : authenticatedBody}</AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Router>
              <AnalyticsWrapper>
                <Switch>
                  <Route path="/yousign/:status">
                    <SigningFinished />
                  </Route>
                  <Route path="/sign/:projectId/:type">
                    <Signature />
                  </Route>
                  <Route path="/sign/:projectId">
                    <Signature />
                  </Route>
                  <Route path="/">
                    <Unknown anonymous forceRetryLogin={forceRetryLogin} />
                  </Route>
                </Switch>
              </AnalyticsWrapper>
            </Router>
          </UnauthenticatedTemplate>
        </>
      )}
    </div>
  );
};

export default App;
