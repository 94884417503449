import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { fr } from "date-fns/locale";
import { CssBaseline, IconButton, ThemeProvider } from "@mui/material";
import { Close } from "@mui/icons-material";
import { SnackbarKey, SnackbarProvider } from "notistack";
import { createRef, FC, PropsWithChildren, useContext } from "react";
import Theme from "./Theme";
import authConfig from "./auth/authConfig";
import "./translation";
import { UserProvider } from "./data/contexts/UserContext";
import { ProjectProvider } from "./data/contexts/ProjectContext";
import GdApolloProvider from "./data/GdApolloProvider";
import { TokenProvider } from "./data/contexts/TokenContext";
import { FormProvider } from "./data/contexts/FormContext";
import { ClientProvider } from "./data/contexts/ClientsContext";
import { SsoContext } from "./data/contexts/SsoContext";
import { AssistantProvider } from "./data/contexts/AssistantContext";
import { SubProjectProvider } from "./data/contexts/SubProjectContext";

const notistackRef = createRef<SnackbarProvider>();
const onClickDismiss = (key: SnackbarKey) => () => {
  notistackRef.current?.closeSnackbar(key);
};

const Wrapper: FC<PropsWithChildren> = ({ children }) => {
  const { type } = useContext(SsoContext);
  const msalConfig = authConfig(type);
  const pca = new PublicClientApplication(msalConfig);

  return (
    <MsalProvider instance={pca}>
      <ThemeProvider theme={Theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
          <TokenProvider>
            <SnackbarProvider
              ref={notistackRef}
              maxSnack={3}
              anchorOrigin={{ horizontal: "center", vertical: "top" }}
              preventDuplicate
              autoHideDuration={10000}
              action={(key) => (
                <IconButton sx={{ color: "#fff" }} onClick={onClickDismiss(key)}>
                  <Close />
                </IconButton>
              )}>
              <GdApolloProvider>
                <FormProvider>
                  <ClientProvider>
                    <SubProjectProvider>
                      <ProjectProvider>
                        <UserProvider>
                          <AssistantProvider>{children}</AssistantProvider>
                        </UserProvider>
                      </ProjectProvider>
                    </SubProjectProvider>
                  </ClientProvider>
                </FormProvider>
              </GdApolloProvider>
            </SnackbarProvider>
          </TokenProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </MsalProvider>
  );
};

export default Wrapper;
