import { ApolloError } from "@apollo/client";
import { createContext, FC, PropsWithChildren, useContext, useState } from "react";
import axios from "axios";
import { log, Lvl } from "../../utils/log";
import {
  AttachmentType,
  Company,
  Document,
  MemberStatus,
  MemberType,
  ProcedureStatus,
  Project,
  ProjectCreate,
  ProjectStatus,
  ProjectUpdate,
  useCompanyGetFromSiretQuery,
  useProjectAskForConventionMutation,
  useProjectCheckConventionMutation,
  useProjectCreateMutation,
  useProjectDuplicateMutation,
  useProjectGetDetailsQuery,
  useProjectGetMineQuery,
  useProjectLaunchMutation,
  useProjectPrepareConventionMutation,
  useProjectPrepareConventionPreviewMutation,
  useProjectUnlaunchMutation,
  useProjectUpdateMutation,
} from "../generated/graphql";
import { TokenContext } from "./TokenContext";
import { ClientContext } from "./ClientsContext";
import { SubProjectContext } from "./SubProjectContext";

export interface ProjectContextProps {
  projects: Project[];
  getMyProjects: () => Promise<void>;
  getProjectDetails: (projectId: string) => Promise<Project | undefined>;
  loading: boolean;
  error?: ApolloError;
  detailsLoading: boolean;
  detailsError?: ApolloError;
  getCompanyFromSiret: (siret: string) => Promise<Company | null>;
  companyLoading: boolean;
  companyError?: ApolloError;
  currentProject: Project | null;
  createProject: (project: ProjectCreate) => Promise<boolean>;
  createError?: ApolloError;
  updateProject: (project: ProjectUpdate) => Promise<boolean>;
  updateError?: ApolloError;
  launchProject: (project: ProjectUpdate) => Promise<boolean>;
  prepareConvention: (project: ProjectUpdate) => Promise<boolean>;
  askForConvention: (project: ProjectUpdate) => Promise<boolean>;
  checkConvention: (project: ProjectUpdate) => Promise<boolean>;
  optimisticUserSignedConvention: () => void;
  uploadFile: (
    projectId: string,
    file: File,
    type: AttachmentType,
    concerns?: string[],
    oId?: string,
    vlId?: string,
    vId?: string,
  ) => Promise<boolean>;
  uploading: boolean;
  unlaunchProject: () => Promise<boolean>;
  saveProjects: (projects: Project[]) => void;
  duplicateProject: (projectId: string, projectName: string) => Promise<Project | undefined>;
  prepareConventionPreview: (projectId: string) => Promise<boolean>;
}

const initialContext: ProjectContextProps = {
  projects: [],
  getMyProjects: () => Promise.resolve(),
  getProjectDetails: () => Promise.resolve(undefined),
  loading: false,
  detailsLoading: false,
  getCompanyFromSiret: () => Promise.resolve(null),
  companyLoading: false,
  currentProject: null,
  createProject: () => Promise.resolve(false),
  updateProject: () => Promise.resolve(false),
  launchProject: () => Promise.resolve(false),
  prepareConvention: () => Promise.resolve(false),
  askForConvention: () => Promise.resolve(false),
  checkConvention: () => Promise.resolve(false),
  optimisticUserSignedConvention: () => {},
  uploadFile: () => Promise.resolve(false),
  uploading: false,
  unlaunchProject: () => Promise.resolve(false),
  saveProjects: () => {},
  duplicateProject: () => Promise.resolve(undefined),
  prepareConventionPreview: () => Promise.resolve(false),
};

export const ProjectContext = createContext<ProjectContextProps>(initialContext);

export const ProjectProvider: FC<PropsWithChildren> = ({ children }) => {
  const { getToken } = useContext(TokenContext);
  const { getMyFleetSubProjects } = useContext(SubProjectContext);
  const [projects, setProjects] = useState<Project[]>([]);
  const [currentProject, setCurrentProject] = useState<Project | null>(null);
  const [latestCheck, setLatestCheck] = useState(0);
  const [companies, setCompanies] = useState<Record<string, Company>>({});
  const [uploading, setUploading] = useState(false);
  const { loading, error, refetch } = useProjectGetMineQuery({ skip: true, errorPolicy: "all" });
  const {
    loading: detailsLoading,
    error: detailsError,
    refetch: detailsRefetch,
  } = useProjectGetDetailsQuery({ skip: true });
  const {
    loading: companyLoading,
    error: companyError,
    refetch: companyRefetch,
  } = useCompanyGetFromSiretQuery({ skip: true });
  const [projectCreate, { error: createError }] = useProjectCreateMutation();
  const [projectUpdate, { error: updateError }] = useProjectUpdateMutation();
  const [projectLaunch] = useProjectLaunchMutation();
  const [projectUnlaunch] = useProjectUnlaunchMutation();
  const [projectPrepareConvention] = useProjectPrepareConventionMutation();
  const [projectCheckConvention] = useProjectCheckConventionMutation();
  const [projectAskForConvention] = useProjectAskForConventionMutation();
  const { extractClients } = useContext(ClientContext);
  const [projectDuplicate] = useProjectDuplicateMutation();
  const [projectPrepareConventionPreview] = useProjectPrepareConventionPreviewMutation();

  const updateLocalProjects = (newProject: Project): void => {
    if (!newProject) return;
    let found = false;
    const newProjects = projects.map((p) => {
      if (p.id === newProject.id) {
        found = true;
        return newProject;
      }
      return p;
    });
    if (!found) newProjects.push(newProject);
    setProjects(newProjects);
  };

  const updateCurrentProject = (newProject: Project): void => {
    setCurrentProject(newProject);
    updateLocalProjects(newProject);
  };

  const saveProjects = (inProjects: Project[]): void => {
    setProjects(inProjects);
    setLatestCheck(new Date().getTime());
    extractClients(inProjects);
  };

  const getMyProjects = async (): Promise<void> => {
    // Check for new projects every 20 minutes
    if (latestCheck < new Date().getTime() - 20 * 60 * 1000) {
      const result = await refetch();
      if (result && result.data) {
        const proj = result.data.projectGetMine ? (result.data.projectGetMine as Project[]) : [];
        saveProjects(proj);
      }
    }
  };

  const getProjectDetails = async (projectId: string): Promise<Project | undefined> => {
    const localProject = projects.find((p) => p.id === projectId);
    if (!localProject || typeof localProject.details?.sector === "undefined") {
      // Get details for project
      const result = await detailsRefetch({ id: projectId });
      if (result && result.data) {
        const newLocalProject = result.data.projectGetById as Project;
        updateCurrentProject(newLocalProject);
        return newLocalProject;
      }
    } else setCurrentProject(localProject);
    return Promise.resolve(localProject);
  };

  const getCompanyFromSiret = async (siret: string): Promise<Company | null> => {
    const localCompany = companies[siret];
    if (!localCompany) {
      // Get company from siret
      const result = await companyRefetch({ siret });
      if (result && result.data) {
        const newLocalCompany = result.data.companyGetFromSiret as Company;
        setCompanies({ ...companies, [newLocalCompany.siret]: newLocalCompany });
        return newLocalCompany;
      }
    }
    return new Promise((resolve) => setTimeout(() => resolve(localCompany), 500));
  };

  const createProject = async (project: ProjectCreate): Promise<boolean> => {
    const result = await projectCreate({ variables: { project } });
    if (result && result.data) {
      const newLocalProject = result.data.projectCreate as Project;
      updateCurrentProject(newLocalProject);
      return true;
    }
    return false;
  };

  const updateProject = async (project: ProjectUpdate): Promise<boolean> => {
    const result = await projectUpdate({ variables: { project } });
    if (result && result.data) {
      const newLocalProject = result.data.projectUpdate as Project;
      if (newLocalProject.status === ProjectStatus.Canceled) {
        setProjects(projects.filter((p) => p.id !== newLocalProject.id));
        setCurrentProject(null);
      } else {
        updateCurrentProject(newLocalProject);
      }
      return true;
    }
    return false;
  };

  const launchProject = async (project: ProjectUpdate): Promise<boolean> => {
    try {
      const result = await projectLaunch({ variables: { project } });
      if (result && result.data) {
        const newLocalProject = result.data.projectLaunch as Project;
        updateCurrentProject(newLocalProject);
        return true;
      }
      return false;
    } catch (err) {
      log("Launch project failed", Lvl.ERROR, err);
      return false;
    }
  };

  const prepareConvention = async (project: ProjectUpdate): Promise<boolean> => {
    try {
      const result = await projectPrepareConvention({ variables: { project } });
      if (result && result.data) {
        const newLocalProject = result.data.projectPrepareConvention as Project;
        updateCurrentProject(newLocalProject);
        return true;
      }
      return false;
    } catch (err) {
      log("Prepare convention failed", Lvl.ERROR, err);
      return false;
    }
  };

  const askForConvention = async (project: ProjectUpdate): Promise<boolean> => {
    try {
      const result = await projectAskForConvention({ variables: { project } });
      if (result && result.data) {
        const newLocalProject = result.data.projectAskForConvention as Project;
        updateCurrentProject(newLocalProject);
        return true;
      }
      return false;
    } catch (err) {
      log("Prepare convention failed", Lvl.ERROR, err);
      return false;
    }
  };

  const checkConvention = async (project: ProjectUpdate): Promise<boolean> => {
    try {
      const result = await projectCheckConvention({ variables: { projectId: project.id } });
      if (result && result.data) {
        const newLocalProject = result.data.projectCheckConvention as Project;
        updateCurrentProject(newLocalProject);
        return true;
      }
      return false;
    } catch (err) {
      log("Check convention failed", Lvl.ERROR, err);
      return false;
    }
  };

  const optimisticUserSignedConvention = (): void => {
    if (currentProject) {
      updateCurrentProject({
        ...currentProject,
        convention: {
          ...currentProject?.convention,
          details: {
            ...currentProject?.convention?.details,
            members: currentProject.convention?.details?.members?.map((m) => {
              if (m.type !== MemberType.User) return m;
              return {
                ...m,
                status: MemberStatus.Done,
              };
            }),
          },
          status:
            currentProject?.convention?.details?.members
              ?.filter((m) => m.type !== MemberType.User)
              .findIndex((m) => m.status !== MemberStatus.Done) === -1
              ? ProcedureStatus.Finished
              : currentProject?.convention?.status,
        },
      });
    }
  };

  const uploadFile = async (
    projectId: string,
    file: File,
    type: AttachmentType,
    concerns?: string[],
    oId?: string,
    vlId?: string,
    vId?: string,
  ): Promise<boolean> => {
    const token = await getToken();
    setUploading(true);
    try {
      const data = new FormData();
      data.append("file", file);
      const result = await axios({
        url: `${process.env.REACT_APP_UPLOAD_API_URL || ""}${
          process.env.REACT_APP_UPLOAD_API_URL?.indexOf("?") === -1 ? "?" : "&"
        }projectId=${projectId}&type=${type}&name=${file.name}${concerns ? `&concerns=${concerns.join(",")}` : ""}${
          oId ? `&operationId=${oId}` : ""
        }${vlId ? `&vehicleListId=${vlId}` : ""}${vId ? `&vehicleId=${vId}` : ""}`,
        method: "POST",
        data,
        headers: {
          authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      });
      if (result.status !== 200) {
        log("Error while uploading", Lvl.ERROR, result);
        return false;
      }
      if (currentProject) {
        const newCurrentProject = { ...currentProject };
        switch (type) {
          case AttachmentType.Billing:
            newCurrentProject.billing = result.data;
            break;
          case AttachmentType.UserBilling:
            newCurrentProject.userBilling = result.data;
            break;
          case AttachmentType.Quote:
            newCurrentProject.quote = result.data;
            break;
          case AttachmentType.Convention:
            newCurrentProject.convention = result.data;
            break;
          case AttachmentType.SwornStatement: {
            let found = false;
            const newSwornStatements: Document[] =
              newCurrentProject.swornStatements?.map((s) => {
                if (s.id === result.data.id) {
                  found = true;
                  return result.data;
                }
                return s;
              }) || [];
            if (!found) newSwornStatements.push(result.data);
            newCurrentProject.swornStatements = newSwornStatements;
            break;
          }
          case AttachmentType.VehicleProof:
          case AttachmentType.VehicleRegistration:
            break;
          default:
            newCurrentProject.attachments = newCurrentProject.attachments
              ? [...newCurrentProject.attachments, result.data]
              : [result.data];
            break;
        }
        updateCurrentProject(newCurrentProject);
      }
      if (
        type === AttachmentType.VehicleProof ||
        type === AttachmentType.VehicleRegistration ||
        type === AttachmentType.VehicleReception
      )
        await getMyFleetSubProjects(true);

      return true;
    } catch (err) {
      log("Error while uploading", Lvl.ERROR, err);
      return false;
    } finally {
      setUploading(false);
    }
  };

  const unlaunchProject = async (): Promise<boolean> => {
    try {
      const result = await projectUnlaunch({ variables: { project: { id: currentProject?.id || "" } } });
      if (result && result.data) {
        const newLocalProject = result.data.projectUnlaunch as Project;
        updateCurrentProject(newLocalProject);
        return true;
      }
      return false;
    } catch (err) {
      log("Unlaunch project failed", Lvl.ERROR, err);
      return false;
    }
  };

  const duplicateProject = async (projectId: string, projectName: string): Promise<Project | undefined> => {
    try {
      const result = await projectDuplicate({ variables: { projectId, projectName } });
      if (result && result.data) {
        const newLocalProject = result.data.projectDuplicate as Project;
        updateCurrentProject(newLocalProject);
        return newLocalProject;
      }
    } catch (err) {
      log("Duplicate project failed", Lvl.ERROR, err);
    }
    return undefined;
  };

  const prepareConventionPreview = async (projectId: string): Promise<boolean> => {
    try {
      const result = await projectPrepareConventionPreview({ variables: { projectId } });
      if (result && result.data) {
        const newLocalProject = result.data.projectPrepareConventionPreview as Project;
        updateCurrentProject(newLocalProject);
        return true;
      }
      return false;
    } catch (err) {
      log("Check convention failed", Lvl.ERROR, err);
      return false;
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        projects,
        getMyProjects,
        getProjectDetails,
        loading,
        error,
        detailsLoading,
        detailsError,
        getCompanyFromSiret,
        companyLoading,
        companyError,
        currentProject,
        createProject,
        createError,
        updateProject,
        updateError,
        prepareConvention,
        askForConvention,
        launchProject,
        checkConvention,
        optimisticUserSignedConvention,
        uploadFile,
        uploading,
        unlaunchProject,
        saveProjects,
        duplicateProject,
        prepareConventionPreview,
      }}>
      {children}
    </ProjectContext.Provider>
  );
};
