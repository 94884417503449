import { Card, Stack, Tab, Tabs, Typography } from "@mui/material";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { SubProjectContext } from "../../data/contexts/SubProjectContext";
import GdButton from "../../utils/GdButton";
import CompanyModal from "./companyModal/CompanyModal";
import VehiclesGrid from "./VehiclesGrid";
import CenteredContent from "../../utils/CenteredContent";
import { UserContext } from "../../data/contexts/UserContext";

const FleetProject: FC<{ projectId: string }> = ({ projectId }) => {
  const { t } = useTranslation(["vehicles", "project"]);
  const { fleetSubProjects } = useContext(SubProjectContext);
  const { userInfo } = useContext(UserContext);
  const [openCompanyModal, setOpenCompanyModal] = useState(false);
  const [tabsIndex, setTabsIndex] = useState(0);

  const subProject = fleetSubProjects.find((sp) => sp.projectId === projectId);
  const userIsRaf = subProject?.userId === userInfo?.id;
  if (typeof subProject === "undefined") return <CenteredContent errorText={t("project:errorWhileGetting")} />;
  if (!subProject?.vehicleLists || subProject?.vehicleLists?.length === 0) {
    return (
      <div className="project-forms-empty">
        <GdButton onClick={() => setOpenCompanyModal(true)} label={t("createFirstCompany")} />
        <CompanyModal
          open={openCompanyModal}
          onClose={() => setOpenCompanyModal(false)}
          subProjectId={subProject?.id || ""}
        />
      </div>
    );
  }
  return (
    <Card sx={{ width: "100%", padding: "24px" }}>
      <div className="project-card">
        <Stack direction="column" alignItems="flex-start" gap="8px" paddingBottom="16px">
          <Typography variant="h4">{subProject?.projectName}</Typography>
          <Typography variant="subtitle1">{t("fleetEntry")}</Typography>
          <Typography variant="subtitle1">{t("hotline")}</Typography>
          {userIsRaf ? <GdButton onClick={() => setOpenCompanyModal(true)} label={t("addCompany")} /> : undefined}
        </Stack>
        <Tabs value={tabsIndex} onChange={(e, newIndex) => setTabsIndex(newIndex)}>
          {subProject?.vehicleLists?.map((vl, index) => (
            <Tab label={vl.signer.company.name} value={index} />
          ))}
        </Tabs>
        <div className="row project-tabs-root">
          <VehiclesGrid
            vehicleList={subProject.vehicleLists[tabsIndex]}
            userIsRaf={userIsRaf}
            subProjectId={subProject.projectId}
          />
        </div>
      </div>
      <CompanyModal
        open={openCompanyModal}
        onClose={() => setOpenCompanyModal(false)}
        subProjectId={subProject?.id || ""}
      />
    </Card>
  );
};

export default FleetProject;
