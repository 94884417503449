import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { SubProjectContext } from "../../data/contexts/SubProjectContext";
import { dataDateConvertToDate, dateConvertToDataDate } from "../../data/dataConvertors";
import { VehicleInput, VehicleList, VehicleRenter } from "../../data/generated/graphql";
import GdButton from "../../utils/GdButton";
import GdDatePicker from "../../utils/GdDatePicker";
import GdModal from "../../utils/GdModal";
import { TextMaskRegistrationNumber } from "../../utils/TextMasks";
import WarningCard from "../../utils/WarningCard";

interface VehicleModalProps {
  open: boolean;
  onClose: () => void;
  vehicleList: VehicleList;
  isCreation: boolean;
  vehicleToModify?: VehicleInput;
}

export const NewEmptyVehicle = (): VehicleInput => ({
  id: uuidv4(),
  registrationNumber: "",
  isRental: true,
});

const VehicleModal: FC<VehicleModalProps> = ({
  open,
  onClose,
  isCreation,
  vehicleToModify = undefined,
  vehicleList,
}) => {
  const [vehicle, setVehicle] = useState<VehicleInput>(NewEmptyVehicle());
  const { updateVehicle } = useContext(SubProjectContext);
  const [creating, setCreating] = useState(false);
  const { t } = useTranslation(["vehicles", "global"]);
  const { enqueueSnackbar } = useSnackbar();
  const isNewVehicle = vehicle?.type === 1 || vehicle?.type === 2;
  useEffect(() => {
    if (isCreation) {
      const newVehicle = NewEmptyVehicle();
      setVehicle({ ...newVehicle });
    } else if (typeof vehicleToModify !== "undefined") {
      setVehicle({ ...vehicleToModify });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleToModify, isCreation]);

  const closeModal = (): void => {
    setVehicle({ ...NewEmptyVehicle() });
    onClose();
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setVehicle((prev) => ({ ...prev, [name]: value } as VehicleInput));
  };

  const handleChangeSelect = (e: SelectChangeEvent, name: string): void => {
    setVehicle((prev) => ({ ...prev, [name]: e.target.value as string } as VehicleInput));
  };

  const handleDateChange = (name: string, date: string): void => {
    setVehicle((prev) => ({ ...prev, [name]: date } as VehicleInput));
  };

  const isFormValid = (vehicle?.registrationNumber?.length || 0) > 0;

  const handleSubmit = async (): Promise<void> => {
    // Check eligibility
    if (vehicle?.isRental ? (vehicle?.deliveryDate || "") < "2025-02-14" : (vehicle?.proofDate || "") < "2025-02-14") {
      enqueueSnackbar(t("vehicleIsNotEligible"), { variant: "error" });
      return;
    }

    if ((vehicle?.registrationNumber?.length || 0) !== 9) {
      enqueueSnackbar(t("registrationNumberIsInvalid"), { variant: "error" });
      return;
    }

    if ((vehicle?.enterpriseAnalyticCode?.length || 0) === 0) {
      enqueueSnackbar(t("analyticCodeMandatory"), { variant: "error" });
      return;
    }

    if (isFormValid && vehicle) {
      setCreating(true);
      try {
        await updateVehicle(vehicleList.id, vehicle);
      } catch (e) {
        enqueueSnackbar(t("registrationNumberAlreadyExists"), { variant: "error" });
        return;
      } finally {
        setCreating(false);
      }
      closeModal();
    }
    closeModal();
  };

  const isDisabled =
    (vehicle.isRental === true ? !vehicle.renter || vehicle.renter.length === 0 : false) ||
    !vehicle.type ||
    Number.isNaN(vehicle.type) ||
    !vehicle.registrationNumber ||
    vehicle.registrationNumber.length !== 9 ||
    !vehicle.firstRegistrationDate ||
    vehicle.firstRegistrationDate.length === 0 ||
    (!isNewVehicle ? !vehicle.registrationDate || vehicle.registrationDate.length === 0 : false) ||
    (vehicle.isRental ? !vehicle.rentDuration || Number.isNaN(vehicle.rentDuration) : false) ||
    !vehicle.enterpriseAnalyticCode ||
    vehicle.enterpriseAnalyticCode.length === 0;

  return (
    <GdModal open={open} onClose={closeModal}>
      <Stack direction="column" gap="16px" justifyContent="center" width="750px">
        <Typography variant="h6">
          {t(!isCreation ? "modifyVehicle" : "add")} {vehicle?.registrationNumber}
        </Typography>
        <WarningCard label={t("fleetEligibility")} />
        <Stack direction="row" alignItems="center" gap="16px" justifyContent="center">
          <Stack width={vehicle.isRental ? "200px" : "100%"}>
            <FormControl margin="none" className="flex1">
              <InputLabel>{t("isRental")}</InputLabel>
              <Select
                label={t("isRental")}
                value={Boolean(vehicle?.isRental).toString()}
                onChange={(e) =>
                  setVehicle((prev) => ({
                    ...prev,
                    isRental: Boolean(e.target.value === "true"),
                  }))
                }>
                <MenuItem value="true">{t("global:yesOrNo.yes")}</MenuItem>
                <MenuItem value="false">{t("global:yesOrNo.no")}</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          {vehicle?.isRental ? (
            <Stack sx={{ width: "100%" }}>
              <FormControl margin="none">
                <InputLabel>{t("renter")}</InputLabel>
                <Select
                  label={t("renter")}
                  value={vehicle?.renter?.toString() || ""}
                  onChange={(e) => handleChangeSelect(e, "renter")}>
                  {Object.values(VehicleRenter).map((r) => (
                    <MenuItem value={VehicleRenter[r]}>{t(`renters.${r}`)}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          ) : undefined}
        </Stack>
        <Stack direction="row" alignItems="center" gap="16px" justifyContent="center">
          <Stack sx={{ width: "100%" }}>
            <FormControl fullWidth margin="none">
              <InputLabel>{t("vehicleType")}</InputLabel>
              <Select
                label={t("vehicleType")}
                value={vehicle?.type?.toString() || ""}
                onChange={(e) => handleChangeSelect(e, "type")}>
                <MenuItem value={1}>{t("vehicleTypeLabel.newLightVehicle")}</MenuItem>
                <MenuItem value={2}>{t("vehicleTypeLabel.newLightUtilityVehicle")}</MenuItem>
                <MenuItem value={3}>{t("vehicleTypeLabel.newRetrofitLightvehicle")}</MenuItem>
                <MenuItem value={4}>{t("vehicleTypeLabel.newRetrofitLightUtilityVehicle")}</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack sx={{ width: "100%" }}>
            <FormControl fullWidth margin="none">
              <InputLabel htmlFor="registrationNumber">{t("registrationNumber")}</InputLabel>
              <OutlinedInput
                label={t("registrationNumber")}
                name="registrationNumber"
                value={vehicle.registrationNumber}
                onChange={handleInputChange}
                fullWidth
                inputComponent={TextMaskRegistrationNumber as any}
              />
            </FormControl>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" gap="16px" justifyContent="center">
          <GdDatePicker
            className="flex1"
            label={t(isNewVehicle ? "registrationDate" : "firstRegistrationDate")}
            disabled={false}
            value={dataDateConvertToDate(vehicle?.firstRegistrationDate || "")}
            onChange={(d: Date | null) => {
              if (Number.isNaN(d?.getTime())) return;
              const date = dateConvertToDataDate(d);
              handleDateChange("firstRegistrationDate", date);
              if (isNewVehicle) {
                handleDateChange("registrationDate", date);
              }
            }}
          />
          {isNewVehicle ? undefined : (
            <GdDatePicker
              className="flex1"
              label={t("registrationDateAfterOperation")}
              disabled={false}
              value={dataDateConvertToDate(vehicle?.registrationDate || "")}
              onChange={(d: Date | null) => {
                if (Number.isNaN(d?.getTime())) return;
                const date = dateConvertToDataDate(d);
                handleDateChange("registrationDate", date);
              }}
            />
          )}
        </Stack>
        <Stack direction="row" alignItems="center" gap="16px" justifyContent="center">
          <div className="flex1">
            <TextField
              label={t(vehicle?.isRental === false ? "invoiceReference" : "rentContractReference")}
              name="proofNumber"
              value={vehicle?.proofNumber || ""}
              onChange={handleInputChange}
              fullWidth
            />
          </div>
          <GdDatePicker
            className="flex1"
            label={t(vehicle?.isRental === false ? "invoiceDate" : "rentContractDate")}
            disabled={false}
            value={dataDateConvertToDate(vehicle?.proofDate || "")}
            onChange={(d: Date | null) => {
              if (Number.isNaN(d?.getTime())) return;
              const date = dateConvertToDataDate(d);
              handleDateChange("proofDate", date);
            }}
          />
        </Stack>
        {vehicle?.isRental === false ? undefined : (
          <Stack direction="row" alignItems="center" gap="16px" justifyContent="center">
            <FormControl margin="none" className="flex1">
              <InputLabel>{t("rentDuration")}</InputLabel>
              <Select
                label={t("rentDuration")}
                value={vehicle?.rentDuration?.toString() || ""}
                onChange={(e) =>
                  setVehicle((prev) => ({
                    ...prev,
                    rentDuration: parseInt(e.target.value, 10),
                  }))
                }>
                <MenuItem value="24">{t("rentDurationChoice", { count: 24 })}</MenuItem>
                <MenuItem value="36">{t("rentDurationChoice", { count: 36 })}</MenuItem>
                <MenuItem value="48">{t("rentDurationChoice", { count: 48 })}</MenuItem>
                <MenuItem value="60">{t("rentDurationChoice", { count: 60 })}</MenuItem>
              </Select>
            </FormControl>
            <GdDatePicker
              className="flex1"
              label={t("deliveryDate")}
              disabled={false}
              value={dataDateConvertToDate(vehicle?.deliveryDate || "")}
              onChange={(d: Date | null) => {
                if (Number.isNaN(d?.getTime())) return;
                const date = dateConvertToDataDate(d);
                handleDateChange("deliveryDate", date);
              }}
            />
          </Stack>
        )}
        <Stack direction="row" alignItems="center" gap="16px" justifyContent="center">
          <TextField
            label={t("enterpriseAnalyticCode")}
            name="enterpriseAnalyticCode"
            value={vehicle?.enterpriseAnalyticCode || ""}
            onChange={handleInputChange}
            fullWidth
          />
        </Stack>
        <Stack direction="row" alignItems="center" gap="16px" justifyContent="center">
          <GdButton label={t("global:cancel")} color="inherit" onClick={closeModal} />
          <GdButton
            label={t("global:save")}
            color="primary"
            onClick={handleSubmit}
            isLoading={creating}
            disabled={isDisabled}
          />
        </Stack>
      </Stack>
    </GdModal>
  );
};

VehicleModal.defaultProps = { vehicleToModify: undefined };

export default VehicleModal;
